<template>
  <div class="container-fluid m-0 p-0">
    <div
      class="row m-0 p-0 justify-content-center align-items-center"
      style="height: 80vh"
    >
      <div
        class="col-10 col-xl-4 p-5 shadow bg-white rounded position-relative"
      >
        <p class="font-800 opp">
          Permission Denied
          <!-- <span class="font-800 opp"></span> -->
          <!-- <span class="page font-600"
            >คุณไม่ได้ครับอนุญาตให้เข้าถึงหน้านี้ / You don't have permission to
            access this page</span
          > -->
        </p>
        <p class="page font-600">
          คุณไม่ได้ครับอนุญาตให้เข้าถึงหน้านี้ / You don't have permission to
          access this page
        </p>

        <button type="button" class="btn bt-main w-100" @click="gotohome()">
          Go To Home Page
        </button>
         <PermissionSvg  class="img-r img-fluid color-team" />
        <!-- <img src="@/assets/Variant17.png" alt="" class="img-r img-fluid" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import PermissionSvg from "../components/svg/PermissionSvg.vue";
export default {
  name: "NotRole",  components: { PermissionSvg },
  methods: {
    gotohome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
.opp {
  font-size: 3.5em;
}
.page {
  font-size: 1.2em;
}
.img-r {
  position: absolute;
  top: 75%;
  left: 110%;
  width: 55%;
  transform: translate(-50%, -50%);
}
</style>
