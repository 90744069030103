<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
  >
    <defs>
      <clipPath id="freepik--clip-path--inject-8">
        <path
          d="M184.18,292.47v9.88a2.56,2.56,0,0,1-1.15,2l-10.38,6.07c-.64.37-1.16.07-1.16-.67v-9.88a2.57,2.57,0,0,1,1.16-2c2.83-1.63,7.54-4.43,10.38-6.06C183.67,291.44,184.18,291.74,184.18,292.47Z"
          style="fill:currentColor"
        ></path>
      </clipPath>
    </defs>
    <g id="freepik--Floor--inject-8">
      <ellipse
        id="freepik--floor--inject-8"
        cx="250"
        cy="345.63"
        rx="240.18"
        ry="138.67"
        style="fill:#f5f5f5"
      ></ellipse>
    </g>
    <g id="freepik--Shadows--inject-8">
      <path
        id="freepik--Shadow--inject-8"
        d="M181.38,429.8l-9.11-5.26c-.74-.43-.74-1.12,0-1.54l65.52-37.83a1.45,1.45,0,0,0,.48-2.06,16,16,0,0,1-2.33-10.82c.87-5.94,5.22-11.44,12.25-15.5,7.59-4.38,17.63-6.8,28.27-6.8s20.68,2.42,28.27,6.8c8,4.63,12.43,10.93,12.43,17.74s-4.41,13.12-12.43,17.75c-7.58,4.38-17.62,6.79-28.25,6.79a67,67,0,0,1-12.78-1.21,4.73,4.73,0,0,0-2.84.45l-10.2,5.89a4.51,4.51,0,0,1-2.84.43l-6.89-1.55a4.51,4.51,0,0,0-2.84.43l-2.07,1.2a3.13,3.13,0,0,0-1.41,2.31l-.23,5.08a3.1,3.1,0,0,1-1.4,2.31l-2.61,1.51a8.29,8.29,0,0,1-2.87.9l-6.09.54a8.53,8.53,0,0,0-2.88.88l-2.51,1.4a2.47,2.47,0,0,0-1.15,2.28l.16,1.21a2.5,2.5,0,0,1-1.14,2.3l-7.56,4.37a6.9,6.9,0,0,1-2.88.77H184.26A6.9,6.9,0,0,1,181.38,429.8Zm105.13-65.13a13.3,13.3,0,0,0-6.42,1.5c-1.22.71-1.94,1.56-1.94,2.29s.72,1.58,1.94,2.28a14.42,14.42,0,0,0,12.85,0c1.21-.7,1.94-1.55,1.94-2.28s-.73-1.58-1.94-2.29A13.37,13.37,0,0,0,286.51,364.67Z"
        style="fill:#e0e0e0"
      ></path>
      <path
        id="freepik--shadow--inject-8"
        d="M90.35,403.72,24.44,365.67c-2.3-1.33-2.3-3.48,0-4.81l128.43-74.15a9.26,9.26,0,0,1,8.33,0l65.91,38.06c2.3,1.33,2.3,3.48,0,4.81L98.68,403.72A9.19,9.19,0,0,1,90.35,403.72Z"
        style="fill:#e0e0e0"
      ></path>
    </g>
    <g id="freepik--Device--inject-8">
      <g id="freepik--device--inject-8">
        <path
          d="M224.2,27.29,96.52,101a10.3,10.3,0,0,0-4.63,8V397c0,3,2.08,4.16,4.63,2.68L224.2,325.93a10.3,10.3,0,0,0,4.64-8V30C228.84,27,226.75,25.8,224.2,27.29Zm-2,264.07c0,2.53-1.77,4.14-4,5.41l-116.08,67c-2.19,1.27-4,.24-4-2.3V136a8.85,8.85,0,0,1,4-6.91L218.17,62c2.21-1.28,4-.25,4,2.29Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M224.2,27.29,96.52,101a10.3,10.3,0,0,0-4.63,8V397c0,3,2.08,4.16,4.63,2.68L224.2,325.93a10.3,10.3,0,0,0,4.64-8V30C228.84,27,226.75,25.8,224.2,27.29Zm-2,264.07c0,2.53-1.77,4.14-4,5.41l-116.08,67c-2.19,1.27-4,.24-4-2.3V136a8.85,8.85,0,0,1,4-6.91L218.17,62c2.21-1.28,4-.25,4,2.29Z"
          style="fill:#fff;opacity:0.75"
        ></path>
        <path
          d="M96,399.93a10.41,10.41,0,0,1-8.72-.29l-5.31-3.07a10.24,10.24,0,0,1-4.65-8V100.62a8.84,8.84,0,0,1,.8-3.47c.16-.38.35-.75.55-1.12,0,0,0,0,0,0l14.6,8.44h0a13.17,13.17,0,0,0-.81,1.7,8.59,8.59,0,0,0-.56,2.87V397C91.89,399.69,93.66,400.93,96,399.93Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M96,399.93a10.41,10.41,0,0,1-8.72-.29l-5.31-3.07a10.24,10.24,0,0,1-4.65-8V100.62a8.84,8.84,0,0,1,.8-3.47c.16-.38.35-.75.55-1.12,0,0,0,0,0,0l14.6,8.44h0a13.17,13.17,0,0,0-.81,1.7,8.59,8.59,0,0,0-.56,2.87V397C91.89,399.69,93.66,400.93,96,399.93Z"
          style="fill:#fff;opacity:0.6000000000000001"
        ></path>
        <path
          d="M158.19,355.88a4.21,4.21,0,0,1-2.14-.55c-1.53-.89-2.38-2.72-2.38-5.15a16.74,16.74,0,0,1,7.56-13.1c2.11-1.22,4.12-1.4,5.65-.51s2.38,2.71,2.38,5.15a16.72,16.72,0,0,1-7.56,13.09A7.12,7.12,0,0,1,158.19,355.88Zm6.55-18.94a6.14,6.14,0,0,0-3,.95h0a15.69,15.69,0,0,0-7.1,12.29c0,2.09.68,3.63,1.91,4.34s2.91.53,4.72-.51a15.7,15.7,0,0,0,7.1-12.29c0-2.09-.68-3.63-1.92-4.35A3.35,3.35,0,0,0,164.74,336.94Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M160.36,70.35c1.72-1,3.11-.19,3.11,1.79a6.86,6.86,0,0,1-3.11,5.39c-1.72,1-3.11.19-3.11-1.79A6.86,6.86,0,0,1,160.36,70.35Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M148.35,89.28l24-13.88c1.43-.82,2.59-.24,2.59,1.32A5.58,5.58,0,0,1,172.38,81l-24,13.87c-1.43.83-2.59.24-2.59-1.31A5.56,5.56,0,0,1,148.35,89.28Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M222.15,291.36c0,2.53-1.77,4.14-4,5.41l-116.08,67c-2.19,1.27-4,.24-4-2.3V136a8.85,8.85,0,0,1,4-6.91L218.17,62c2.21-1.28,4-.25,4,2.29Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M228.79,29.23c-.33-2.42-2.26-3.29-4.59-1.95L96.52,101a9.4,9.4,0,0,0-3.28,3.47L78.64,96a9.49,9.49,0,0,1,3.28-3.45L209.58,18.86a10.27,10.27,0,0,1,9.29,0l5.33,3.06A10.37,10.37,0,0,1,228.79,29.23Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M228.79,29.23c-.33-2.42-2.26-3.29-4.59-1.95L96.52,101a9.4,9.4,0,0,0-3.28,3.47L78.64,96a9.49,9.49,0,0,1,3.28-3.45L209.58,18.86a10.27,10.27,0,0,1,9.29,0l5.33,3.06A10.37,10.37,0,0,1,228.79,29.23Z"
          style="fill:#fff;opacity:0.9"
        ></path>
        <path
          d="M121.93,134.36a.31.31,0,0,1,0-.2v-1l-1.5.87c-.09.05-.13,0-.13-.13v-.35a.92.92,0,0,1,0-.19,1,1,0,0,1,0-.18l1.59-4.35a.93.93,0,0,1,.08-.17.32.32,0,0,1,.1-.1l.21-.12c.06,0,.11,0,.14,0s0,.1,0,.21V132l.33-.19c.07,0,.11,0,.13,0s0,.1,0,.21v.23a.55.55,0,0,1,0,.24.22.22,0,0,1-.13.14l-.33.19v1a.55.55,0,0,1,0,.24.33.33,0,0,1-.14.15l-.25.15C122,134.38,122,134.39,121.93,134.36Zm0-2v-2.39l-1.08,3Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M123.71,132.75a3.12,3.12,0,0,1-.44-2,6.14,6.14,0,0,1,.44-2.46,3,3,0,0,1,1.24-1.5,2,2,0,0,1,.6-.23.72.72,0,0,1,.47.07.13.13,0,0,1,0,.09.89.89,0,0,1,0,.11l-.14.5a.38.38,0,0,1-.05.11h-.07a.84.84,0,0,0-.76.12,2.11,2.11,0,0,0-.85,1,4.78,4.78,0,0,0-.31,1.83,2.47,2.47,0,0,0,.3,1.47c.2.25.47.27.82.07a2.33,2.33,0,0,0,.37-.27,1.68,1.68,0,0,0,.29-.32v-1.56l-.62.36c-.07,0-.11,0-.13,0a.31.31,0,0,1,0-.2v-.22a.57.57,0,0,1,0-.25.29.29,0,0,1,.13-.14l1-.57c.06,0,.11,0,.14,0a.44.44,0,0,1,0,.25v2.22a.84.84,0,0,1,0,.19.24.24,0,0,1-.06.15A3.08,3.08,0,0,1,125,132.8C124.41,133.11,124,133.09,123.71,132.75Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M210.9,76c-.17-.25-.38-.32-.59-.19l-6,3.43a2.32,2.32,0,0,0-.69,1.82v3.36a2,2,0,0,0,.26,1.07.7.7,0,0,0,.24.22.39.39,0,0,0,.36,0l6-3.44a2.26,2.26,0,0,0,.69-1.81V77.11A2,2,0,0,0,210.9,76Zm-6.83,9a.92.92,0,0,1-.1-.49V81.25a1.8,1.8,0,0,1,.53-1.39l6-3.43a.23.23,0,0,1,.19,0,.27.27,0,0,1,.08.07,1,1,0,0,1,.09.49v3.36a1.79,1.79,0,0,1-.52,1.38l-6,3.44C204.22,85.2,204.13,85.19,204.07,85.09Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M210.19,77.45v2.86a1.47,1.47,0,0,1-.74,1.28l-4.86,2.79a.13.13,0,0,1-.19-.1v-3a1.39,1.39,0,0,1,.39-1l5.18-3A.14.14,0,0,1,210.19,77.45Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M211.39,77c.16-.1.3.38.3,1.07v.63a2.43,2.43,0,0,1-.31,1.45l-.3.21V77.18Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M132.29,127.11a2.3,2.3,0,0,1-.87,1.84c-.48.27-.87-.1-.87-.84a2.3,2.3,0,0,1,.87-1.84C131.9,126,132.29,126.37,132.29,127.11Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M131.42,124.08a5.23,5.23,0,0,0-2,3c-.08.27,0,.53.1.61h0c.16.11.41-.11.5-.45a3.66,3.66,0,0,1,1.43-2.14.92.92,0,0,1,1.42.5c.1.23.34.16.51-.13h0a.88.88,0,0,0,.09-.71A1.31,1.31,0,0,0,131.42,124.08Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M131.42,121.93a8.1,8.1,0,0,0-3.11,4.42c-.1.29-.07.58.07.67h0c.16.1.39-.1.5-.42a6.68,6.68,0,0,1,2.54-3.62,1.68,1.68,0,0,1,2.53.69c.11.2.34.13.5-.15h0a.89.89,0,0,0,.08-.75A2.07,2.07,0,0,0,131.42,121.93Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M154.61,110.58a.24.24,0,0,1,0-.16.7.7,0,0,1,.06-.19l1.25-2.24.1-.15a.36.36,0,0,1,.1-.11l.45-.26a.09.09,0,0,1,.11,0,.21.21,0,0,1,.05.15v5.72a.49.49,0,0,1-.05.2.24.24,0,0,1-.11.13l-.46.27s-.08,0-.11,0a.18.18,0,0,1-.05-.14v-4.38l-.82,1.46a.19.19,0,0,1-.11.11s-.07,0-.1,0Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M157.52,108.9a.2.2,0,0,1,0-.16.33.33,0,0,1,.06-.19l1.25-2.24.09-.16A.38.38,0,0,1,159,106l.45-.25a.08.08,0,0,1,.11,0,.2.2,0,0,1,0,.14v5.73a.41.41,0,0,1,0,.19.42.42,0,0,1-.11.14l-.47.27a.09.09,0,0,1-.11,0,.2.2,0,0,1-.05-.14v-4.38l-.81,1.46a.25.25,0,0,1-.12.11.1.1,0,0,1-.1,0Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M161.34,106.45a.09.09,0,0,1,.11,0,.21.21,0,0,1,.05.15v.87a.45.45,0,0,1-.05.19.25.25,0,0,1-.11.13l-.54.31a.08.08,0,0,1-.11,0,.21.21,0,0,1,0-.15v-.86a.48.48,0,0,1,0-.2.37.37,0,0,1,.11-.13Zm0,3.1a.08.08,0,0,1,.11,0,.18.18,0,0,1,.05.14v.87a.45.45,0,0,1-.05.19.33.33,0,0,1-.11.14l-.54.3a.08.08,0,0,1-.11,0,.2.2,0,0,1,0-.14v-.87a.48.48,0,0,1,0-.2.37.37,0,0,1,.11-.13Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M164.41,109.12a.09.09,0,0,1-.11,0,.18.18,0,0,1,0-.14V107.9l-1.79,1a.09.09,0,0,1-.11,0,.2.2,0,0,1,0-.14v-.63a.67.67,0,0,1,0-.24c0-.08,0-.16.08-.25l1.77-4.4a.71.71,0,0,1,.23-.36l.44-.25a.08.08,0,0,1,.11,0,.18.18,0,0,1,0,.14v3.54l.5-.28a.08.08,0,0,1,.11,0,.21.21,0,0,1,.05.15v.63a.45.45,0,0,1-.05.19.25.25,0,0,1-.11.13l-.5.29v1.07a.48.48,0,0,1,0,.2.29.29,0,0,1-.11.13Zm-.15-2.33v-1.9l-1,2.47Z"
          style="fill:#fafafa"
        ></path>
        <path
          d="M166.73,101.82a.43.43,0,0,1,0-.19.33.33,0,0,1,.11-.14l2.17-1.25a.09.09,0,0,1,.11,0,.2.2,0,0,1,0,.14V101a.48.48,0,0,1,0,.2.37.37,0,0,1-.11.13l-1.62.93-.06,1.18a1.41,1.41,0,0,1,.3-.3l.25-.16a1.49,1.49,0,0,1,.6-.21.69.69,0,0,1,.48.11.94.94,0,0,1,.32.47,2.51,2.51,0,0,1,.11.81,4.16,4.16,0,0,1-.12,1,3.26,3.26,0,0,1-.32.86,2.44,2.44,0,0,1-.48.65,2.55,2.55,0,0,1-.59.46,1.5,1.5,0,0,1-.68.25.65.65,0,0,1-.47-.11.82.82,0,0,1-.28-.39,1.88,1.88,0,0,1-.11-.6.45.45,0,0,1,0-.2c0-.06.07-.11.12-.13l.46-.27a.11.11,0,0,1,.11,0,.17.17,0,0,1,.06.11.49.49,0,0,0,.11.27.3.3,0,0,0,.17.11.44.44,0,0,0,.22,0,.72.72,0,0,0,.25-.12,1.26,1.26,0,0,0,.53-.58,1.79,1.79,0,0,0,.2-.86.63.63,0,0,0-.2-.55.47.47,0,0,0-.53,0,1.18,1.18,0,0,0-.27.2.86.86,0,0,0-.16.18,1.11,1.11,0,0,1-.12.16.37.37,0,0,1-.13.12l-.53.3a.08.08,0,0,1-.11,0,.21.21,0,0,1,0-.15Z"
          style="fill:#fafafa"
        ></path>
        <polygon
          points="107.6 142.71 107.6 140.87 109.2 139.94 109.2 141.79 107.6 142.71"
          style="fill:#fafafa"
        ></polygon>
        <polygon
          points="110 141.33 110 137.63 111.6 136.71 111.6 140.4 110 141.33"
          style="fill:#fafafa"
        ></polygon>
        <polygon
          points="112.4 139.94 112.4 134.4 114 133.48 114 139.02 112.4 139.94"
          style="fill:#fafafa"
        ></polygon>
        <polygon
          points="114.8 138.56 114.8 131.16 116.4 130.24 116.4 137.63 114.8 138.56"
          style="fill:#fafafa"
        ></polygon>
      </g>
    </g>
    <g id="freepik--Stars--inject-8">
      <g id="freepik--stars--inject-8">
        <path
          d="M372,90.87a2.08,2.08,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48-.05l-3.43,1.21-1.17-3a.8.8,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.25-2.5-.44-.36-1.19.22-1.48,1.3l-2.19,8.09a2.4,2.4,0,0,1-.71,1.21L351,96.22c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.39,1.39,0,0,1,.27,1.13l-.83,8a1.24,1.24,0,0,0,.33,1.19c.24.16,2.57,2,3.12,2.38l.12.1h0c.27.14.65,0,1-.47l4.37-6.07c.28-.38.61-.57.88-.51l4.38,1c.69.16,1.51-1.22,1.37-2.33l-.83-7a2.82,2.82,0,0,1,.27-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M372,90.87a2.08,2.08,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48-.05l-3.43,1.21-1.17-3a.8.8,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.25-2.5-.44-.36-1.19.22-1.48,1.3l-2.19,8.09a2.4,2.4,0,0,1-.71,1.21L351,96.22c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.39,1.39,0,0,1,.27,1.13l-.83,8a1.24,1.24,0,0,0,.33,1.19c.24.16,2.57,2,3.12,2.38l.12.1h0c.27.14.65,0,1-.47l4.37-6.07c.28-.38.61-.57.88-.51l4.38,1c.69.16,1.51-1.22,1.37-2.33l-.83-7a2.82,2.82,0,0,1,.27-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M363.39,84.12c-.45-.28-1.15.29-1.43,1.33l-2.19,8.09a3.07,3.07,0,0,1-.38.84L356.15,92a2.76,2.76,0,0,0,.42-.86L358.76,83c.29-1.08,1.05-1.66,1.48-1.29C360.32,81.77,362.82,83.62,363.39,84.12Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M363.39,84.12c-.45-.28-1.15.29-1.43,1.33l-2.19,8.09a3.07,3.07,0,0,1-.38.84L356.15,92a2.76,2.76,0,0,0,.42-.86L358.76,83c.29-1.08,1.05-1.66,1.48-1.29C360.32,81.77,362.82,83.62,363.39,84.12Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M363.65,84.47,365.84,90a.54.54,0,0,0,.71.39l4.9-1.73c.77-.27,1.08,1,.52,2.18l-3.54,7.36a2.82,2.82,0,0,0-.27,1.45l.83,7c.14,1.11-.68,2.49-1.37,2.33l-4.38-1c-.27-.06-.6.13-.88.51L358,114.59c-.7,1-1.51.52-1.38-.74l.84-8a1.4,1.4,0,0,0-.27-1.13l-3.54-3.27c-.57-.52-.26-2.17.52-2.79l4.89-3.92a2.41,2.41,0,0,0,.72-1.21L362,85.45C362.3,84.17,363.31,83.59,363.65,84.47Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M357.45,105.42h0a1,1,0,0,0-.28-.69l-3.53-3.27a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.25,1.26l3.54,3.27a.88.88,0,0,1,.24.47h0a1.87,1.87,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.26,1.26,0,0,0,.34,1.19c.25.16,2.62,2,3.14,2.4a1.31,1.31,0,0,1-.28-1.13l.84-8c0-.06,0-.12,0-.18v0C357.45,105.51,357.45,105.42,357.45,105.42Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M357.45,105.42h0a1,1,0,0,0-.28-.69l-3.53-3.27a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.25,1.26l3.54,3.27a.88.88,0,0,1,.24.47h0a1.87,1.87,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.26,1.26,0,0,0,.34,1.19c.25.16,2.62,2,3.14,2.4a1.31,1.31,0,0,1-.28-1.13l.84-8c0-.06,0-.12,0-.18v0C357.45,105.51,357.45,105.42,357.45,105.42Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M334.53,127.16a2,2,0,0,0,0-2c-.08-.19-3.16-2.45-3.28-2.53a.48.48,0,0,0-.47-.06l-3.43,1.22-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.19.22-1.49,1.3l-2.18,8.09a2.33,2.33,0,0,1-.72,1.2l-4.89,3.92c-.78.63-1.09,2.28-.52,2.79l3.54,3.27a1.41,1.41,0,0,1,.27,1.14l-.84,8a1.24,1.24,0,0,0,.34,1.18c.24.17,2.57,2,3.12,2.39a.27.27,0,0,0,.12.09h0c.27.15.65,0,1-.46l4.38-6.07c.28-.38.61-.57.88-.51l4.38,1c.69.16,1.5-1.22,1.37-2.34l-.83-7a2.7,2.7,0,0,1,.27-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M334.53,127.16a2,2,0,0,0,0-2c-.08-.19-3.16-2.45-3.28-2.53a.48.48,0,0,0-.47-.06l-3.43,1.22-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.19.22-1.49,1.3l-2.18,8.09a2.33,2.33,0,0,1-.72,1.2l-4.89,3.92c-.78.63-1.09,2.28-.52,2.79l3.54,3.27a1.41,1.41,0,0,1,.27,1.14l-.84,8a1.24,1.24,0,0,0,.34,1.18c.24.17,2.57,2,3.12,2.39a.27.27,0,0,0,.12.09h0c.27.15.65,0,1-.46l4.38-6.07c.28-.38.61-.57.88-.51l4.38,1c.69.16,1.5-1.22,1.37-2.34l-.83-7a2.7,2.7,0,0,1,.27-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M326,120.41c-.45-.28-1.15.29-1.44,1.33l-2.19,8.09a2.87,2.87,0,0,1-.37.83l-3.25-2.42a2.43,2.43,0,0,0,.42-.87l2.2-8.08c.29-1.08,1-1.66,1.48-1.3C322.87,118.06,325.37,119.9,326,120.41Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M326,120.41c-.45-.28-1.15.29-1.44,1.33l-2.19,8.09a2.87,2.87,0,0,1-.37.83l-3.25-2.42a2.43,2.43,0,0,0,.42-.87l2.2-8.08c.29-1.08,1-1.66,1.48-1.3C322.87,118.06,325.37,119.9,326,120.41Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M326.21,120.76l2.19,5.56a.54.54,0,0,0,.71.39L334,125c.78-.28,1.09,1,.53,2.18L331,134.52a2.7,2.7,0,0,0-.27,1.45l.83,7c.13,1.12-.68,2.5-1.37,2.34l-4.38-1c-.27-.06-.6.13-.88.51l-4.38,6.07c-.69,1-1.5.52-1.37-.75l.84-8a1.35,1.35,0,0,0-.28-1.13l-3.54-3.27c-.56-.52-.25-2.17.53-2.79l4.89-3.92a2.33,2.33,0,0,0,.71-1.21l2.19-8.09C324.86,120.46,325.86,119.88,326.21,120.76Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M320,141.71h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.51,1.51,0,0,1-.24-1.29l-3.2-2.42a1.42,1.42,0,0,0,.25,1.25l3.54,3.27a1,1,0,0,1,.24.48h0a1.82,1.82,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.63,2,3.15,2.4a1.26,1.26,0,0,1-.28-1.13l.83-8a1.15,1.15,0,0,0,0-.18v0C320,141.8,320,141.71,320,141.71Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M320,141.71h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.51,1.51,0,0,1-.24-1.29l-3.2-2.42a1.42,1.42,0,0,0,.25,1.25l3.54,3.27a1,1,0,0,1,.24.48h0a1.82,1.82,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.63,2,3.15,2.4a1.26,1.26,0,0,1-.28-1.13l.83-8a1.15,1.15,0,0,0,0-.18v0C320,141.8,320,141.71,320,141.71Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M307.36,180.19a2.05,2.05,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.54a.53.53,0,0,0-.48,0l-3.43,1.21-1.17-3a.69.69,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.25-2.5-.44-.36-1.19.22-1.48,1.3L292,180.4a2.36,2.36,0,0,1-.71,1.21l-4.9,3.92c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.41,1.41,0,0,1,.27,1.14l-.83,8a1.22,1.22,0,0,0,.33,1.18c.24.17,2.57,2,3.12,2.39a.47.47,0,0,0,.12.09h0c.27.15.65,0,1-.46l4.37-6.07c.28-.38.61-.57.88-.51l4.38,1c.7.16,1.51-1.22,1.37-2.34l-.83-7a2.8,2.8,0,0,1,.27-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M307.36,180.19a2.05,2.05,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.54a.53.53,0,0,0-.48,0l-3.43,1.21-1.17-3a.69.69,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.25-2.5-.44-.36-1.19.22-1.48,1.3L292,180.4a2.36,2.36,0,0,1-.71,1.21l-4.9,3.92c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.41,1.41,0,0,1,.27,1.14l-.83,8a1.22,1.22,0,0,0,.33,1.18c.24.17,2.57,2,3.12,2.39a.47.47,0,0,0,.12.09h0c.27.15.65,0,1-.46l4.37-6.07c.28-.38.61-.57.88-.51l4.38,1c.7.16,1.51-1.22,1.37-2.34l-.83-7a2.8,2.8,0,0,1,.27-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M298.78,173.44c-.45-.28-1.15.29-1.43,1.33l-2.19,8.09a2.88,2.88,0,0,1-.38.83l-3.24-2.42a2.82,2.82,0,0,0,.42-.87l2.19-8.08c.29-1.08,1.05-1.66,1.48-1.3C295.71,171.09,298.21,172.93,298.78,173.44Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M298.78,173.44c-.45-.28-1.15.29-1.43,1.33l-2.19,8.09a2.88,2.88,0,0,1-.38.83l-3.24-2.42a2.82,2.82,0,0,0,.42-.87l2.19-8.08c.29-1.08,1.05-1.66,1.48-1.3C295.71,171.09,298.21,172.93,298.78,173.44Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M299,173.79l2.19,5.56a.54.54,0,0,0,.71.39l4.9-1.73c.77-.28,1.08,1,.52,2.18l-3.54,7.36a2.8,2.8,0,0,0-.27,1.45l.83,7c.14,1.12-.67,2.5-1.37,2.34l-4.38-1c-.27-.06-.6.13-.88.51l-4.37,6.07c-.7,1-1.51.52-1.38-.75l.84-8a1.39,1.39,0,0,0-.27-1.13L289,190.78c-.57-.52-.26-2.17.52-2.79l4.89-3.92a2.41,2.41,0,0,0,.72-1.21l2.18-8.09C297.69,173.48,298.7,172.9,299,173.79Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M292.84,194.74h0a1,1,0,0,0-.28-.69L289,190.78a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.45,1.45,0,0,0,.25,1.25l3.54,3.27a.88.88,0,0,1,.24.48h0a1.82,1.82,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.24,1.24,0,0,0,.34,1.18c.25.17,2.62,2,3.14,2.4a1.3,1.3,0,0,1-.28-1.13l.84-8c0-.06,0-.13,0-.18v0C292.84,194.82,292.84,194.74,292.84,194.74Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M292.84,194.74h0a1,1,0,0,0-.28-.69L289,190.78a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.45,1.45,0,0,0,.25,1.25l3.54,3.27a.88.88,0,0,1,.24.48h0a1.82,1.82,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.24,1.24,0,0,0,.34,1.18c.25.17,2.62,2,3.14,2.4a1.3,1.3,0,0,1-.28-1.13l.84-8c0-.06,0-.13,0-.18v0C292.84,194.82,292.84,194.74,292.84,194.74Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M297.83,237.09a2.07,2.07,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.54a.53.53,0,0,0-.48-.05l-3.42,1.21-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.2.22-1.49,1.3l-2.19,8.08a2.29,2.29,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.38,1.38,0,0,1,.28,1.14l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.57,2,3.12,2.39a.47.47,0,0,0,.12.09h0c.28.15.65,0,1-.46l4.38-6.07c.27-.38.6-.57.88-.51l4.37,1c.7.17,1.51-1.21,1.38-2.33l-.84-7a2.71,2.71,0,0,1,.28-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M297.83,237.09a2.07,2.07,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.54a.53.53,0,0,0-.48-.05l-3.42,1.21-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.2.22-1.49,1.3l-2.19,8.08a2.29,2.29,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.38,1.38,0,0,1,.28,1.14l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.57,2,3.12,2.39a.47.47,0,0,0,.12.09h0c.28.15.65,0,1-.46l4.38-6.07c.27-.38.6-.57.88-.51l4.37,1c.7.17,1.51-1.21,1.38-2.33l-.84-7a2.71,2.71,0,0,1,.28-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M289.25,230.34c-.45-.28-1.16.29-1.44,1.33l-2.19,8.09a2.64,2.64,0,0,1-.38.83L282,238.16a2.47,2.47,0,0,0,.42-.86l2.19-8.08c.3-1.08,1.05-1.66,1.49-1.3C286.17,228,288.67,229.83,289.25,230.34Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M289.25,230.34c-.45-.28-1.16.29-1.44,1.33l-2.19,8.09a2.64,2.64,0,0,1-.38.83L282,238.16a2.47,2.47,0,0,0,.42-.86l2.19-8.08c.3-1.08,1.05-1.66,1.49-1.3C286.17,228,288.67,229.83,289.25,230.34Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M289.51,230.69l2.19,5.56a.54.54,0,0,0,.71.39l4.89-1.73c.78-.28,1.09,1,.53,2.18l-3.54,7.36a2.71,2.71,0,0,0-.28,1.45l.84,7c.13,1.12-.68,2.5-1.38,2.33l-4.37-1c-.28-.06-.61.13-.88.51l-4.38,6.07c-.69,1-1.51.52-1.37-.75l.83-8A1.35,1.35,0,0,0,283,251l-3.54-3.27c-.56-.52-.25-2.17.53-2.79l4.89-3.92a2.4,2.4,0,0,0,.71-1.21l2.19-8.09C288.16,230.38,289.16,229.8,289.51,230.69Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M283.3,251.64h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.47,1.47,0,0,1-.25-1.29L276,244a1.45,1.45,0,0,0,.25,1.25l3.54,3.27a1,1,0,0,1,.25.48h0a2.61,2.61,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.63,2,3.14,2.4a1.3,1.3,0,0,1-.27-1.13l.83-8a1.15,1.15,0,0,0,0-.18v0A2.17,2.17,0,0,0,283.3,251.64Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M283.3,251.64h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.47,1.47,0,0,1-.25-1.29L276,244a1.45,1.45,0,0,0,.25,1.25l3.54,3.27a1,1,0,0,1,.25.48h0a2.61,2.61,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.63,2,3.14,2.4a1.3,1.3,0,0,1-.27-1.13l.83-8a1.15,1.15,0,0,0,0-.18v0A2.17,2.17,0,0,0,283.3,251.64Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M308.83,280.66a2,2,0,0,0,0-2.05c-.08-.19-3.17-2.45-3.28-2.53a.48.48,0,0,0-.47-.06l-3.43,1.22-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.19.22-1.49,1.3l-2.18,8.09a2.41,2.41,0,0,1-.72,1.21L287.82,286c-.78.63-1.09,2.28-.52,2.8l3.54,3.26a1.41,1.41,0,0,1,.27,1.14l-.84,8a1.24,1.24,0,0,0,.34,1.18c.24.17,2.57,2,3.12,2.39a.27.27,0,0,0,.12.09h0c.27.15.65,0,1-.46l4.38-6.07c.28-.38.6-.57.88-.51l4.38,1c.69.16,1.5-1.22,1.37-2.33l-.83-7a2.61,2.61,0,0,1,.27-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M308.83,280.66a2,2,0,0,0,0-2.05c-.08-.19-3.17-2.45-3.28-2.53a.48.48,0,0,0-.47-.06l-3.43,1.22-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.19.22-1.49,1.3l-2.18,8.09a2.41,2.41,0,0,1-.72,1.21L287.82,286c-.78.63-1.09,2.28-.52,2.8l3.54,3.26a1.41,1.41,0,0,1,.27,1.14l-.84,8a1.24,1.24,0,0,0,.34,1.18c.24.17,2.57,2,3.12,2.39a.27.27,0,0,0,.12.09h0c.27.15.65,0,1-.46l4.38-6.07c.28-.38.6-.57.88-.51l4.38,1c.69.16,1.5-1.22,1.37-2.33l-.83-7a2.61,2.61,0,0,1,.27-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M300.25,273.91c-.45-.28-1.15.29-1.44,1.33l-2.19,8.09a3.06,3.06,0,0,1-.37.84L293,281.74a2.61,2.61,0,0,0,.42-.87l2.19-8.08c.3-1.08,1.05-1.66,1.49-1.3C297.17,271.56,299.67,273.4,300.25,273.91Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M300.25,273.91c-.45-.28-1.15.29-1.44,1.33l-2.19,8.09a3.06,3.06,0,0,1-.37.84L293,281.74a2.61,2.61,0,0,0,.42-.87l2.19-8.08c.3-1.08,1.05-1.66,1.49-1.3C297.17,271.56,299.67,273.4,300.25,273.91Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M300.51,274.26l2.19,5.56a.54.54,0,0,0,.71.39l4.89-1.73c.78-.28,1.09,1,.53,2.18L305.29,288a2.61,2.61,0,0,0-.27,1.45l.83,7c.13,1.11-.68,2.49-1.37,2.33l-4.38-1c-.28-.06-.6.13-.88.51l-4.38,6.07c-.69,1-1.5.52-1.37-.75l.83-8a1.32,1.32,0,0,0-.27-1.13l-3.54-3.27c-.56-.52-.25-2.17.53-2.79l4.89-3.92a2.4,2.4,0,0,0,.71-1.21l2.19-8.09C299.16,274,300.16,273.38,300.51,274.26Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M294.3,295.21h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.51,1.51,0,0,1-.24-1.29L287,287.54a1.48,1.48,0,0,0,.26,1.26l3.54,3.26a1,1,0,0,1,.24.48h0a2.61,2.61,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.63,2,3.15,2.41a1.29,1.29,0,0,1-.28-1.14l.83-8a1.09,1.09,0,0,0,0-.18v0C294.31,295.3,294.3,295.21,294.3,295.21Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M294.3,295.21h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.51,1.51,0,0,1-.24-1.29L287,287.54a1.48,1.48,0,0,0,.26,1.26l3.54,3.26a1,1,0,0,1,.24.48h0a2.61,2.61,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.17,2.63,2,3.15,2.41a1.29,1.29,0,0,1-.28-1.14l.83-8a1.09,1.09,0,0,0,0-.18v0C294.31,295.3,294.3,295.21,294.3,295.21Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M336.84,300.71a2.07,2.07,0,0,0,0-2c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48-.05l-3.42,1.21-1.17-3a1.08,1.08,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.2.22-1.49,1.3l-2.19,8.09a2.33,2.33,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.35,1.35,0,0,1,.28,1.13l-.84,8a1.29,1.29,0,0,0,.33,1.19c.25.16,2.57,2,3.13,2.38a.52.52,0,0,0,.11.1h0c.28.14.65,0,1-.47l4.38-6.07c.27-.38.6-.57.88-.51l4.37,1c.7.16,1.51-1.22,1.38-2.33l-.84-7a2.73,2.73,0,0,1,.28-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M336.84,300.71a2.07,2.07,0,0,0,0-2c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48-.05l-3.42,1.21-1.17-3a1.08,1.08,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.2.22-1.49,1.3l-2.19,8.09a2.33,2.33,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.35,1.35,0,0,1,.28,1.13l-.84,8a1.29,1.29,0,0,0,.33,1.19c.25.16,2.57,2,3.13,2.38a.52.52,0,0,0,.11.1h0c.28.14.65,0,1-.47l4.38-6.07c.27-.38.6-.57.88-.51l4.37,1c.7.16,1.51-1.22,1.38-2.33l-.84-7a2.73,2.73,0,0,1,.28-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M328.26,294c-.45-.28-1.16.29-1.44,1.33l-2.19,8.09a2.8,2.8,0,0,1-.38.84L321,301.79a2.56,2.56,0,0,0,.42-.86l2.19-8.09c.3-1.08,1.05-1.66,1.49-1.29C325.18,291.61,327.68,293.46,328.26,294Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M328.26,294c-.45-.28-1.16.29-1.44,1.33l-2.19,8.09a2.8,2.8,0,0,1-.38.84L321,301.79a2.56,2.56,0,0,0,.42-.86l2.19-8.09c.3-1.08,1.05-1.66,1.49-1.29C325.18,291.61,327.68,293.46,328.26,294Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M328.52,294.31l2.19,5.56a.54.54,0,0,0,.71.39l4.89-1.73c.78-.27,1.09,1,.53,2.18l-3.54,7.36a2.73,2.73,0,0,0-.28,1.45l.84,7c.13,1.11-.68,2.49-1.38,2.33l-4.37-1c-.28-.06-.61.13-.88.51l-4.38,6.07c-.69,1-1.51.52-1.37-.74l.83-8a1.36,1.36,0,0,0-.27-1.13l-3.54-3.27c-.56-.52-.25-2.17.53-2.79l4.89-3.92a2.4,2.4,0,0,0,.71-1.21l2.19-8.09C327.17,294,328.17,293.43,328.52,294.31Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M322.31,315.26h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.27a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.25,1.26l3.54,3.27a.88.88,0,0,1,.25.47h0a2.72,2.72,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.26,1.26,0,0,0,.33,1.18c.25.17,2.63,2,3.15,2.41a1.28,1.28,0,0,1-.28-1.13l.83-8a1.09,1.09,0,0,0,0-.18v0C322.32,315.35,322.31,315.26,322.31,315.26Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M322.31,315.26h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.27a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.25,1.26l3.54,3.27a.88.88,0,0,1,.25.47h0a2.72,2.72,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.26,1.26,0,0,0,.33,1.18c.25.17,2.63,2,3.15,2.41a1.28,1.28,0,0,1-.28-1.13l.83-8a1.09,1.09,0,0,0,0-.18v0C322.32,315.35,322.31,315.26,322.31,315.26Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M375.38,291.17a2.05,2.05,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48,0l-3.43,1.21-1.17-3a.8.8,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.25-2.5-.44-.36-1.19.22-1.48,1.3L360,291.39a2.4,2.4,0,0,1-.71,1.21l-4.9,3.92c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.39,1.39,0,0,1,.27,1.13l-.83,8a1.24,1.24,0,0,0,.33,1.19c.24.16,2.57,2,3.12,2.38l.12.09h0c.27.14.65,0,1-.47l4.37-6.07c.28-.38.61-.57.88-.51l4.38,1c.7.16,1.51-1.22,1.37-2.33l-.83-7a2.82,2.82,0,0,1,.27-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M375.38,291.17a2.05,2.05,0,0,0,0-2.05c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48,0l-3.43,1.21-1.17-3a.8.8,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.25-2.5-.44-.36-1.19.22-1.48,1.3L360,291.39a2.4,2.4,0,0,1-.71,1.21l-4.9,3.92c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.39,1.39,0,0,1,.27,1.13l-.83,8a1.24,1.24,0,0,0,.33,1.19c.24.16,2.57,2,3.12,2.38l.12.09h0c.27.14.65,0,1-.47l4.37-6.07c.28-.38.61-.57.88-.51l4.38,1c.7.16,1.51-1.22,1.37-2.33l-.83-7a2.82,2.82,0,0,1,.27-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M366.8,284.42c-.45-.28-1.15.29-1.43,1.33l-2.19,8.09a3.07,3.07,0,0,1-.38.84l-3.24-2.43a2.76,2.76,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.48-1.29C363.73,282.07,366.23,283.92,366.8,284.42Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M366.8,284.42c-.45-.28-1.15.29-1.43,1.33l-2.19,8.09a3.07,3.07,0,0,1-.38.84l-3.24-2.43a2.76,2.76,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.48-1.29C363.73,282.07,366.23,283.92,366.8,284.42Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M367.06,284.77l2.19,5.56a.54.54,0,0,0,.71.39l4.9-1.73c.77-.27,1.08,1,.52,2.18l-3.54,7.36a2.82,2.82,0,0,0-.27,1.45l.83,7c.14,1.11-.67,2.49-1.37,2.33l-4.38-1c-.27-.06-.6.13-.88.51l-4.37,6.07c-.7,1-1.51.52-1.38-.74l.84-8a1.4,1.4,0,0,0-.27-1.13l-3.54-3.27c-.57-.52-.26-2.17.52-2.79l4.89-3.92a2.41,2.41,0,0,0,.72-1.21l2.18-8.09C365.71,284.47,366.72,283.89,367.06,284.77Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M360.86,305.72h0a1,1,0,0,0-.28-.69l-3.53-3.27a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.25,1.26l3.54,3.27a.81.81,0,0,1,.24.47h0a1.87,1.87,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.26,1.26,0,0,0,.34,1.19c.25.16,2.62,2,3.14,2.4a1.31,1.31,0,0,1-.28-1.13l.84-8c0-.06,0-.12,0-.18v0C360.86,305.81,360.86,305.72,360.86,305.72Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M360.86,305.72h0a1,1,0,0,0-.28-.69l-3.53-3.27a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.25,1.26l3.54,3.27a.81.81,0,0,1,.24.47h0a1.87,1.87,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.26,1.26,0,0,0,.34,1.19c.25.16,2.62,2,3.14,2.4a1.31,1.31,0,0,1-.28-1.13l.84-8c0-.06,0-.12,0-.18v0C360.86,305.81,360.86,305.72,360.86,305.72Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M412.82,254.89a2.07,2.07,0,0,0,0-2.06c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48-.05l-3.42,1.21-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.49-.44-.37-1.2.21-1.49,1.29l-2.19,8.09a2.4,2.4,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.32,1.32,0,0,1,.27,1.13l-.83,8a1.24,1.24,0,0,0,.33,1.18c.25.16,2.57,2,3.12,2.39l.12.09h0c.28.15.65,0,1-.47l4.38-6.06c.27-.39.6-.58.88-.51l4.37,1c.7.16,1.51-1.21,1.38-2.33l-.84-7a2.83,2.83,0,0,1,.27-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M412.82,254.89a2.07,2.07,0,0,0,0-2.06c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48-.05l-3.42,1.21-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.49-.44-.37-1.2.21-1.49,1.29l-2.19,8.09a2.4,2.4,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.32,1.32,0,0,1,.27,1.13l-.83,8a1.24,1.24,0,0,0,.33,1.18c.25.16,2.57,2,3.12,2.39l.12.09h0c.28.15.65,0,1-.47l4.38-6.06c.27-.39.6-.58.88-.51l4.37,1c.7.16,1.51-1.21,1.38-2.33l-.84-7a2.83,2.83,0,0,1,.27-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M404.24,248.13c-.44-.28-1.15.3-1.43,1.34l-2.19,8.08a2.94,2.94,0,0,1-.38.84L397,256a2.47,2.47,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.49-1.29C401.17,245.78,403.67,247.63,404.24,248.13Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M404.24,248.13c-.44-.28-1.15.3-1.43,1.34l-2.19,8.08a2.94,2.94,0,0,1-.38.84L397,256a2.47,2.47,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.49-1.29C401.17,245.78,403.67,247.63,404.24,248.13Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M404.51,248.49l2.18,5.56a.54.54,0,0,0,.72.38l4.89-1.73c.78-.27,1.09,1,.52,2.19l-3.54,7.35a2.83,2.83,0,0,0-.27,1.45l.84,7c.13,1.12-.68,2.49-1.38,2.33l-4.37-1c-.28-.07-.61.12-.88.51l-4.38,6.06c-.69,1-1.51.53-1.37-.74l.83-8a1.41,1.41,0,0,0-.27-1.14l-3.54-3.27c-.56-.52-.25-2.17.52-2.79l4.9-3.92a2.43,2.43,0,0,0,.71-1.21l2.19-8.08C403.15,248.18,404.16,247.6,404.51,248.49Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M398.3,269.43h0a1.1,1.1,0,0,0-.27-.69l-3.54-3.26a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.46,1.46,0,0,0,.25,1.25l3.54,3.27a.92.92,0,0,1,.25.48h0a2.48,2.48,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.16,2.63,2,3.14,2.4a1.3,1.3,0,0,1-.27-1.13l.83-8c0-.07,0-.13,0-.19h0C398.3,269.52,398.3,269.43,398.3,269.43Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M398.3,269.43h0a1.1,1.1,0,0,0-.27-.69l-3.54-3.26a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.46,1.46,0,0,0,.25,1.25l3.54,3.27a.92.92,0,0,1,.25.48h0a2.48,2.48,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.16,2.63,2,3.14,2.4a1.3,1.3,0,0,1-.27-1.13l.83-8c0-.07,0-.13,0-.19h0C398.3,269.52,398.3,269.43,398.3,269.43Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M440,201.86a2.05,2.05,0,0,0,0-2.05c-.09-.2-3.17-2.46-3.28-2.54a.5.5,0,0,0-.48-.05l-3.43,1.21-1.17-3a.69.69,0,0,0-.14-.24h0l0,0c-.27-.28-3.16-2.42-3.25-2.49-.44-.37-1.19.22-1.48,1.29l-2.19,8.09a2.4,2.4,0,0,1-.71,1.21L419,207.2c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.4,1.4,0,0,1,.27,1.13l-.83,8a1.22,1.22,0,0,0,.33,1.18c.24.16,2.57,2,3.12,2.39l.12.09h0c.27.15.65,0,1-.47l4.37-6.06c.28-.39.61-.58.88-.51l4.38,1c.69.16,1.51-1.21,1.37-2.33l-.83-7a2.83,2.83,0,0,1,.27-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M440,201.86a2.05,2.05,0,0,0,0-2.05c-.09-.2-3.17-2.46-3.28-2.54a.5.5,0,0,0-.48-.05l-3.43,1.21-1.17-3a.69.69,0,0,0-.14-.24h0l0,0c-.27-.28-3.16-2.42-3.25-2.49-.44-.37-1.19.22-1.48,1.29l-2.19,8.09a2.4,2.4,0,0,1-.71,1.21L419,207.2c-.77.62-1.08,2.27-.52,2.79l3.54,3.27a1.4,1.4,0,0,1,.27,1.13l-.83,8a1.22,1.22,0,0,0,.33,1.18c.24.16,2.57,2,3.12,2.39l.12.09h0c.27.15.65,0,1-.47l4.37-6.06c.28-.39.61-.58.88-.51l4.38,1c.69.16,1.51-1.21,1.37-2.33l-.83-7a2.83,2.83,0,0,1,.27-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M431.41,195.1c-.45-.28-1.15.3-1.43,1.34l-2.19,8.08a2.94,2.94,0,0,1-.38.84l-3.24-2.43a2.65,2.65,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.48-1.29C428.34,192.75,430.84,194.6,431.41,195.1Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M431.41,195.1c-.45-.28-1.15.3-1.43,1.34l-2.19,8.08a2.94,2.94,0,0,1-.38.84l-3.24-2.43a2.65,2.65,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.48-1.29C428.34,192.75,430.84,194.6,431.41,195.1Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M431.67,195.46l2.19,5.56a.54.54,0,0,0,.71.38l4.9-1.73c.77-.27,1.08,1,.52,2.19l-3.54,7.35a2.83,2.83,0,0,0-.27,1.45l.83,7c.14,1.12-.68,2.49-1.37,2.33l-4.38-1c-.27-.07-.6.12-.88.51L426,225.57c-.7,1-1.51.53-1.38-.74l.84-8a1.41,1.41,0,0,0-.27-1.14l-3.54-3.27c-.57-.51-.26-2.16.52-2.79l4.89-3.92a2.33,2.33,0,0,0,.72-1.2l2.18-8.09C430.32,195.15,431.33,194.57,431.67,195.46Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M425.47,216.41h0a1,1,0,0,0-.28-.69l-3.53-3.26a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.46,1.46,0,0,0,.25,1.25l3.54,3.27a.91.91,0,0,1,.24.48h0a1.78,1.78,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.24,1.24,0,0,0,.34,1.18c.25.16,2.62,2,3.14,2.4a1.3,1.3,0,0,1-.28-1.13l.84-8c0-.07,0-.13,0-.19h0C425.47,216.49,425.47,216.41,425.47,216.41Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M425.47,216.41h0a1,1,0,0,0-.28-.69l-3.53-3.26a1.47,1.47,0,0,1-.25-1.29l-3.2-2.42a1.46,1.46,0,0,0,.25,1.25l3.54,3.27a.91.91,0,0,1,.24.48h0a1.78,1.78,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.24,1.24,0,0,0,.34,1.18c.25.16,2.62,2,3.14,2.4a1.3,1.3,0,0,1-.28-1.13l.84-8c0-.07,0-.13,0-.19h0C425.47,216.49,425.47,216.41,425.47,216.41Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M449.53,145a2,2,0,0,0,0-2.05c-.09-.2-3.17-2.46-3.28-2.54a.48.48,0,0,0-.47-.05l-3.43,1.21-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.28-3.16-2.42-3.24-2.49-.44-.37-1.19.22-1.49,1.29l-2.18,8.09a2.41,2.41,0,0,1-.72,1.21l-4.89,3.92c-.78.62-1.09,2.27-.52,2.79l3.54,3.27a1.4,1.4,0,0,1,.27,1.13l-.84,8a1.24,1.24,0,0,0,.34,1.18c.24.16,2.57,2,3.12,2.39a.38.38,0,0,0,.12.09h0c.27.15.65,0,1-.47l4.38-6.06c.27-.39.6-.58.88-.51l4.38,1c.69.16,1.5-1.21,1.37-2.33l-.84-7a2.75,2.75,0,0,1,.28-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M449.53,145a2,2,0,0,0,0-2.05c-.09-.2-3.17-2.46-3.28-2.54a.48.48,0,0,0-.47-.05l-3.43,1.21-1.17-3a.89.89,0,0,0-.14-.24h0l0,0c-.27-.28-3.16-2.42-3.24-2.49-.44-.37-1.19.22-1.49,1.29l-2.18,8.09a2.41,2.41,0,0,1-.72,1.21l-4.89,3.92c-.78.62-1.09,2.27-.52,2.79l3.54,3.27a1.4,1.4,0,0,1,.27,1.13l-.84,8a1.24,1.24,0,0,0,.34,1.18c.24.16,2.57,2,3.12,2.39a.38.38,0,0,0,.12.09h0c.27.15.65,0,1-.47l4.38-6.06c.27-.39.6-.58.88-.51l4.38,1c.69.16,1.5-1.21,1.37-2.33l-.84-7a2.75,2.75,0,0,1,.28-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M441,138.2c-.45-.28-1.15.3-1.44,1.34l-2.19,8.08a2.69,2.69,0,0,1-.38.84L433.7,146a2.47,2.47,0,0,0,.42-.86l2.19-8.09c.3-1.08,1.05-1.66,1.49-1.29C437.87,135.85,440.37,137.7,441,138.2Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M441,138.2c-.45-.28-1.15.3-1.44,1.34l-2.19,8.08a2.69,2.69,0,0,1-.38.84L433.7,146a2.47,2.47,0,0,0,.42-.86l2.19-8.09c.3-1.08,1.05-1.66,1.49-1.29C437.87,135.85,440.37,137.7,441,138.2Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M441.21,138.56l2.19,5.56a.53.53,0,0,0,.71.38l4.89-1.73c.78-.27,1.09,1,.53,2.19L446,152.32a2.69,2.69,0,0,0-.28,1.44l.84,7c.13,1.12-.68,2.49-1.37,2.33l-4.38-1c-.28-.07-.61.12-.88.51l-4.38,6.06c-.69,1-1.5.53-1.37-.74l.83-8a1.34,1.34,0,0,0-.27-1.14l-3.54-3.27c-.56-.51-.25-2.16.53-2.79l4.89-3.92a2.32,2.32,0,0,0,.71-1.2l2.19-8.09C439.86,138.25,440.86,137.67,441.21,138.56Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M435,159.51h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.51,1.51,0,0,1-.24-1.29l-3.21-2.42a1.46,1.46,0,0,0,.26,1.25l3.54,3.27a1,1,0,0,1,.24.48h0a2.59,2.59,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.16,2.63,2,3.15,2.4a1.27,1.27,0,0,1-.28-1.13l.83-8a1.28,1.28,0,0,0,0-.19h0A2.17,2.17,0,0,0,435,159.51Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M435,159.51h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.26a1.51,1.51,0,0,1-.24-1.29l-3.21-2.42a1.46,1.46,0,0,0,.26,1.25l3.54,3.27a1,1,0,0,1,.24.48h0a2.59,2.59,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.27,1.27,0,0,0,.33,1.18c.25.16,2.63,2,3.15,2.4a1.27,1.27,0,0,1-.28-1.13l.83-8a1.28,1.28,0,0,0,0-.19h0A2.17,2.17,0,0,0,435,159.51Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M438.52,101.39a2.07,2.07,0,0,0,0-2.06c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48,0L431.38,98l-1.17-3a1.38,1.38,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.49-.44-.37-1.2.21-1.49,1.29l-2.19,8.09a2.33,2.33,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.35,1.35,0,0,1,.28,1.13l-.84,8a1.28,1.28,0,0,0,.33,1.18c.25.16,2.57,2,3.12,2.39l.12.09h0c.28.15.65,0,1-.47l4.38-6.07c.27-.38.6-.57.88-.5l4.37,1c.7.16,1.51-1.22,1.38-2.33l-.84-7a2.75,2.75,0,0,1,.28-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M438.52,101.39a2.07,2.07,0,0,0,0-2.06c-.09-.19-3.17-2.45-3.28-2.53a.5.5,0,0,0-.48,0L431.38,98l-1.17-3a1.38,1.38,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.49-.44-.37-1.2.21-1.49,1.29l-2.19,8.09a2.33,2.33,0,0,1-.71,1.21l-4.89,3.92c-.78.62-1.09,2.27-.53,2.79l3.54,3.27a1.35,1.35,0,0,1,.28,1.13l-.84,8a1.28,1.28,0,0,0,.33,1.18c.25.16,2.57,2,3.12,2.39l.12.09h0c.28.15.65,0,1-.47l4.38-6.07c.27-.38.6-.57.88-.5l4.37,1c.7.16,1.51-1.22,1.38-2.33l-.84-7a2.75,2.75,0,0,1,.28-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M430,94.63c-.45-.28-1.16.3-1.44,1.34l-2.19,8.08a2.69,2.69,0,0,1-.38.84l-3.24-2.43a2.56,2.56,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.49-1.29C426.87,92.28,429.37,94.13,430,94.63Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M430,94.63c-.45-.28-1.16.3-1.44,1.34l-2.19,8.08a2.69,2.69,0,0,1-.38.84l-3.24-2.43a2.56,2.56,0,0,0,.42-.86l2.19-8.09c.29-1.08,1.05-1.66,1.49-1.29C426.87,92.28,429.37,94.13,430,94.63Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M430.21,95l2.18,5.57a.54.54,0,0,0,.72.38L438,99.2c.78-.27,1.09,1,.52,2.19L435,108.74a2.75,2.75,0,0,0-.28,1.45l.84,7c.13,1.11-.68,2.49-1.38,2.33l-4.37-1c-.28-.07-.61.12-.88.5l-4.38,6.07c-.69,1-1.51.53-1.37-.74l.83-8a1.41,1.41,0,0,0-.27-1.14L420.19,112c-.56-.52-.25-2.17.53-2.79l4.89-3.92a2.36,2.36,0,0,0,.71-1.21L428.51,96C428.86,94.68,429.86,94.1,430.21,95Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M424,115.93h0a1.1,1.1,0,0,0-.28-.69L420.19,112a1.44,1.44,0,0,1-.25-1.28l-3.2-2.43a1.47,1.47,0,0,0,.25,1.26l3.54,3.27a.88.88,0,0,1,.25.48h0a2.48,2.48,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.28,1.28,0,0,0,.33,1.19c.25.16,2.63,2,3.14,2.4a1.31,1.31,0,0,1-.27-1.13l.83-8a1.28,1.28,0,0,0,0-.19h0C424,116,424,115.93,424,115.93Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M424,115.93h0a1.1,1.1,0,0,0-.28-.69L420.19,112a1.44,1.44,0,0,1-.25-1.28l-3.2-2.43a1.47,1.47,0,0,0,.25,1.26l3.54,3.27a.88.88,0,0,1,.25.48h0a2.48,2.48,0,0,1,0,.41,1.93,1.93,0,0,1,0,.24l-.84,8a1.28,1.28,0,0,0,.33,1.19c.25.16,2.63,2,3.14,2.4a1.31,1.31,0,0,1-.27-1.13l.83-8a1.28,1.28,0,0,0,0-.19h0C424,116,424,115.93,424,115.93Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M410.52,81.33a2,2,0,0,0,0-2c-.09-.19-3.17-2.45-3.28-2.53a.48.48,0,0,0-.47,0l-3.43,1.21-1.17-3a1.08,1.08,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.19.22-1.49,1.3l-2.18,8.09a2.41,2.41,0,0,1-.72,1.21l-4.89,3.92c-.78.62-1.09,2.27-.52,2.79l3.54,3.27a1.39,1.39,0,0,1,.27,1.13l-.84,8a1.26,1.26,0,0,0,.34,1.19c.24.16,2.56,2,3.12,2.38a.52.52,0,0,0,.11.1h0c.27.14.65,0,1-.47L400.91,99c.27-.38.6-.57.88-.51l4.38,1c.69.16,1.5-1.22,1.37-2.33l-.84-7a2.73,2.73,0,0,1,.28-1.45Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M410.52,81.33a2,2,0,0,0,0-2c-.09-.19-3.17-2.45-3.28-2.53a.48.48,0,0,0-.47,0l-3.43,1.21-1.17-3a1.08,1.08,0,0,0-.14-.24h0l0,0c-.27-.29-3.16-2.43-3.24-2.5-.44-.36-1.19.22-1.49,1.3l-2.18,8.09a2.41,2.41,0,0,1-.72,1.21l-4.89,3.92c-.78.62-1.09,2.27-.52,2.79l3.54,3.27a1.39,1.39,0,0,1,.27,1.13l-.84,8a1.26,1.26,0,0,0,.34,1.19c.24.16,2.56,2,3.12,2.38a.52.52,0,0,0,.11.1h0c.27.14.65,0,1-.47L400.91,99c.27-.38.6-.57.88-.51l4.38,1c.69.16,1.5-1.22,1.37-2.33l-.84-7a2.73,2.73,0,0,1,.28-1.45Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M401.94,74.58c-.45-.28-1.15.29-1.44,1.33L398.31,84a2.8,2.8,0,0,1-.38.84l-3.24-2.43a2.56,2.56,0,0,0,.42-.86l2.19-8.09c.3-1.08,1.05-1.66,1.49-1.29C398.86,72.23,401.36,74.08,401.94,74.58Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M401.94,74.58c-.45-.28-1.15.29-1.44,1.33L398.31,84a2.8,2.8,0,0,1-.38.84l-3.24-2.43a2.56,2.56,0,0,0,.42-.86l2.19-8.09c.3-1.08,1.05-1.66,1.49-1.29C398.86,72.23,401.36,74.08,401.94,74.58Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M402.2,74.93l2.19,5.56a.54.54,0,0,0,.71.39L410,79.15c.78-.27,1.09,1,.53,2.18L407,88.69a2.73,2.73,0,0,0-.28,1.45l.84,7c.13,1.11-.68,2.49-1.37,2.33l-4.38-1c-.28-.06-.61.13-.88.51l-4.38,6.07c-.69,1-1.5.52-1.37-.74l.83-8a1.33,1.33,0,0,0-.27-1.13l-3.54-3.27c-.56-.52-.25-2.17.53-2.79l4.89-3.92a2.4,2.4,0,0,0,.71-1.21l2.19-8.09C400.85,74.63,401.85,74.05,402.2,74.93Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M396,95.88h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.27a1.53,1.53,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.26,1.26l3.54,3.27a1,1,0,0,1,.24.47h0a2.72,2.72,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.29,1.29,0,0,0,.33,1.19c.25.16,2.63,2,3.15,2.4a1.28,1.28,0,0,1-.28-1.13l.83-8a1.09,1.09,0,0,0,0-.18v0C396,96,396,95.88,396,95.88Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M396,95.88h0a1.1,1.1,0,0,0-.28-.69l-3.54-3.27a1.53,1.53,0,0,1-.25-1.29l-3.2-2.42a1.48,1.48,0,0,0,.26,1.26l3.54,3.27a1,1,0,0,1,.24.47h0a2.72,2.72,0,0,1,0,.42,1.93,1.93,0,0,1,0,.24l-.84,8a1.29,1.29,0,0,0,.33,1.19c.25.16,2.63,2,3.15,2.4a1.28,1.28,0,0,1-.28-1.13l.83-8a1.09,1.09,0,0,0,0-.18v0C396,96,396,95.88,396,95.88Z"
          style="opacity:0.1"
        ></path>
      </g>
    </g>
    <g id="freepik--Padlock--inject-8">
      <g id="freepik--padlock--inject-8">
        <path
          d="M396.64,182.82l-18-10.37h0a4.71,4.71,0,0,0-4.68,0l-.61.35-39.17,22.62h0a14.23,14.23,0,0,0-7.11,12.32h0v29.81c0,2.19.74,3.75,1.95,4.53s16.59,9.5,17.8,10.27,3.13.76,5.16-.41l39.17-22.62A15.71,15.71,0,0,0,398.3,217V187.19A5.53,5.53,0,0,0,396.64,182.82Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M346.9,252.38l0,0c-1.21-.77-16.51-9.44-17.8-10.27s-1.95-2.34-1.95-4.53V207.74a14.26,14.26,0,0,1,1.9-7.1L347,211a14.52,14.52,0,0,0-2.07,7v29.81C344.91,250,345.66,251.61,346.9,252.38Z"
          style="opacity:0.1"
        ></path>
        <path
          d="M398.3,187.19V217a15.71,15.71,0,0,1-7.11,12.32L352,251.94c-3.93,2.27-7.11.43-7.11-4.11V218A15.73,15.73,0,0,1,352,205.69l39.17-22.61C395.12,180.81,398.3,182.65,398.3,187.19Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M378.68,172.45l17.46,10.08c-1.28-.69-3-.57-4.95.55L352,205.69a14.57,14.57,0,0,0-5,5.33l-18-10.38h0a14.33,14.33,0,0,1,5.22-5.21l39.16-22.62.61-.35a4.73,4.73,0,0,1,4.68,0Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M375.42,227.17l-7.63,4.4,1.18-13.8c-.91-.48-1.49-1.67-1.49-3.38,0-3,1.85-6.57,4.13-7.88s4.12.08,4.12,3.12a10.42,10.42,0,0,1-1.49,5.1Z"
          style="fill:#455a64"
        ></path>
        <polygon
          points="375.42 227.17 368.51 223.18 367.79 231.57 375.42 227.17"
          style="fill:#37474f"
        ></polygon>
        <path
          d="M371.61,206.51a8.93,8.93,0,0,0-3.52,4.67l6.15,3.55h0a10.42,10.42,0,0,0,1.49-5.1C375.73,206.59,373.89,205.19,371.61,206.51Z"
          style="fill:#37474f"
        ></path>
        <path
          d="M379.4,182.46h0V152.22c0-6.05-2.29-10.85-6.28-13.15-3.81-2.2-8.52-1.9-13.27.84-8.71,5-15.54,17.31-15.54,28V198.2h0a2,2,0,0,0,1.17,1.64,6.27,6.27,0,0,0,5.66,0,2,2,0,0,0,1.17-1.64h0V167.86c0-7.78,5.29-17.41,11.54-21,2.14-1.23,4.06-1.54,5.27-.84,1.42.82,2.28,3.15,2.28,6.22v30.24h0a2,2,0,0,0,1.17,1.62,6.25,6.25,0,0,0,5.65,0,2,2,0,0,0,1.18-1.62h0Z"
          style="fill:#37474f"
        ></path>
      </g>
    </g>
    <g id="freepik--Account--inject-8">
      <g id="freepik--account--inject-8">
        <g id="freepik--Code--inject-8">
          <path
            d="M166.7,127.54l102.74-59.3c1.18-.68,2.14-.13,2.14,1.24v41.06a4.73,4.73,0,0,1-2.14,3.71L166.7,173.56c-1.18.68-2.14.13-2.14-1.24V131.25A4.76,4.76,0,0,1,166.7,127.54Z"
            style="fill:currentColor;opacity:0.7000000000000001"
          ></path>
          <path
            d="M165.19,129.14,159.73,126a4.36,4.36,0,0,0-.63,2.11v41.08a1.51,1.51,0,0,0,.62,1.37c.39.24,5.06,2.91,5.43,3.14a1.52,1.52,0,0,1-.58-1.36V131.25A4.34,4.34,0,0,1,165.19,129.14Z"
            style="fill:currentColor;opacity:0.30000000000000004"
          ></path>
          <path
            d="M270.87,68c-.76-.46-5-2.9-5.36-3.1a1.51,1.51,0,0,0-1.53.13L161.25,124.39a4.39,4.39,0,0,0-1.52,1.59l5.46,3.16a4.4,4.4,0,0,1,1.51-1.6l102.74-59.3A1.57,1.57,0,0,1,270.87,68Z"
            style="fill:currentColor;opacity:0.5"
          ></path>
        </g>
        <path
          d="M181.19,139.07a3.33,3.33,0,0,1-1.67-.43,4.11,4.11,0,0,1-1.81-3.76,10.79,10.79,0,0,1,4.95-8.56h0a3.7,3.7,0,0,1,6,3.44,10.81,10.81,0,0,1-4.94,8.57A5.1,5.1,0,0,1,181.19,139.07Z"
          style="fill:#fff"
        ></path>
        <path
          d="M190.8,140.32c-1.85-1.07-4.19-.89-6.61.51l-2.73,1.57A18.46,18.46,0,0,0,173,157v4.69l20.74-11.95v-3.23C193.75,143.66,192.68,141.4,190.8,140.32Z"
          style="fill:#fff"
        ></path>
        <path
          d="M201.09,121.31c0,3.08,2.31,4.25,5.16,2.61L254.65,96a11.05,11.05,0,0,0,5.16-8.56c0-3.09-2.31-4.26-5.16-2.61l-48.4,27.94A11.07,11.07,0,0,0,201.09,121.31Z"
          style="fill:#fff;opacity:0.7000000000000001"
        ></path>
        <path
          d="M206,116.59,254.86,88.4c.95-.55,1.72-.16,1.72.87a3.66,3.66,0,0,1-1.72,2.85L206,120.31c-.95.55-1.72.16-1.72-.87A3.69,3.69,0,0,1,206,116.59Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M201.09,139.93c0,3.09,2.31,4.26,5.16,2.61l48.4-27.94a11.07,11.07,0,0,0,5.16-8.57c0-3.08-2.31-4.25-5.16-2.61l-48.4,28A11.05,11.05,0,0,0,201.09,139.93Z"
          style="fill:#fff;opacity:0.7000000000000001"
        ></path>
        <path
          d="M209.16,133.42c.89-.52,1.61-.1,1.61.93a3.55,3.55,0,0,1-1.61,2.79c-.89.52-1.61.1-1.61-.93A3.55,3.55,0,0,1,209.16,133.42Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M214.25,130.48c.89-.52,1.61-.1,1.61.93a3.55,3.55,0,0,1-1.61,2.79c-.89.52-1.61.1-1.61-.93A3.55,3.55,0,0,1,214.25,130.48Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M219.34,127.54c.89-.52,1.61-.1,1.61.93a3.55,3.55,0,0,1-1.61,2.79c-.89.52-1.61.1-1.61-.93A3.55,3.55,0,0,1,219.34,127.54Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M224.43,124.6c.89-.52,1.61-.1,1.61.93a3.58,3.58,0,0,1-1.61,2.8c-.89.51-1.61.09-1.61-.94A3.55,3.55,0,0,1,224.43,124.6Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M229.52,121.66c.89-.51,1.61-.1,1.61.93a3.58,3.58,0,0,1-1.61,2.8c-.89.51-1.61.09-1.61-.94A3.58,3.58,0,0,1,229.52,121.66Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M234.61,118.72c.89-.51,1.61-.1,1.61.93a3.58,3.58,0,0,1-1.61,2.8c-.89.51-1.61.1-1.61-.93A3.58,3.58,0,0,1,234.61,118.72Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M239.7,115.78c.89-.51,1.61-.09,1.61.93a3.58,3.58,0,0,1-1.61,2.8c-.89.51-1.61.1-1.61-.93A3.58,3.58,0,0,1,239.7,115.78Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M244.79,112.84c.89-.51,1.61-.09,1.61.94a3.56,3.56,0,0,1-1.61,2.79c-.89.51-1.61.1-1.61-.93A3.58,3.58,0,0,1,244.79,112.84Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M249.88,109.91c.89-.52,1.61-.1,1.61.93a3.55,3.55,0,0,1-1.61,2.79c-.89.52-1.61.1-1.61-.93A3.55,3.55,0,0,1,249.88,109.91Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M255,107c.89-.52,1.61-.1,1.61.93a3.55,3.55,0,0,1-1.61,2.79c-.89.52-1.61.1-1.61-.93A3.55,3.55,0,0,1,255,107Z"
          style="fill:#455a64"
        ></path>
      </g>
    </g>
    <g id="freepik--Folder--inject-8">
      <g id="freepik--folder--inject-8">
        <path
          d="M261.08,142.66a1,1,0,0,0-.39-.9l-.1-.06-2.06-1.2h0a1,1,0,0,0-1,.1l-41.13,23.72-1.2-4.83a1.36,1.36,0,0,0-.6-.79h0l-2.14-1.24h0a1.27,1.27,0,0,0-1.3,0l-17.5,10.1a3.13,3.13,0,0,0-1.42,2.47v58.36a1,1,0,0,0,.38.9h0l2.2,1.27h0a1,1,0,0,0,1-.11l63.89-36.89a3.14,3.14,0,0,0,1.43-2.46Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M214.57,158.68a1.28,1.28,0,0,0-1.28.06l-17.5,10.1a3,3,0,0,0-1,1.07l-2.14-1.25a2.87,2.87,0,0,1,1-1.06l17.5-10.1a1.27,1.27,0,0,1,1.3,0h0Z"
          style="opacity:0.2"
        ></path>
        <path
          d="M260.59,141.7a1.09,1.09,0,0,0-.93.14L218.48,165.6a1.25,1.25,0,0,1-1.9-.76l-.19-.52,41.13-23.72a1.29,1.29,0,0,1,.63-.19.72.72,0,0,1,.37.09h0Z"
          style="opacity:0.2"
        ></path>
        <path
          d="M261.08,142.66l0,48.48a3.11,3.11,0,0,1-1.42,2.46l-64.05,37a.84.84,0,0,1-1.26-.72V171.31a3.1,3.1,0,0,1,1.42-2.46l17.5-10.11a1.31,1.31,0,0,1,1.28-.06l0,0h0a1.31,1.31,0,0,1,.6.78l1.2,4.83.19.52a1.24,1.24,0,0,0,1.89.76l41.18-23.76a1.05,1.05,0,0,1,.93-.13l.06,0h.05A1,1,0,0,1,261.08,142.66Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M261.08,142.66l0,48.48a3.11,3.11,0,0,1-1.42,2.46l-64.05,37a.84.84,0,0,1-1.26-.72V171.31a3.1,3.1,0,0,1,1.42-2.46l17.5-10.11a1.31,1.31,0,0,1,1.28-.06l0,0h0a1.31,1.31,0,0,1,.6.78l1.2,4.83.19.52a1.24,1.24,0,0,0,1.89.76l41.18-23.76a1.05,1.05,0,0,1,.93-.13l.06,0h.05A1,1,0,0,1,261.08,142.66Z"
          style="opacity:0.30000000000000004"
        ></path>
        <path
          d="M194.89,230.63l-.07,0-2.2-1.28a1,1,0,0,1-.38-.91V170.06a2.9,2.9,0,0,1,.42-1.4l2.13,1.25h0a2.87,2.87,0,0,0-.42,1.4v58.54A.83.83,0,0,0,194.89,230.63Z"
          style="opacity:0.5"
        ></path>
      </g>
    </g>
    <g id="freepik--Image--inject-8">
      <g id="freepik--image--inject-8">
        <path
          d="M182.46,175.58a1.16,1.16,0,0,0-1,.14l-67.24,38.83a3.44,3.44,0,0,0-1.56,2.7V278a1.11,1.11,0,0,0,.43,1c-.27-.17-2.63-1.5-2.92-1.68a1.11,1.11,0,0,1-.45-1V215.56a3.44,3.44,0,0,1,1.57-2.7L178.48,174a1.1,1.1,0,0,1,1.12-.1C179.85,174.08,181.9,175.24,182.46,175.58Z"
          style="fill:currentColor;opacity:0.7000000000000001"
        ></path>
        <path
          d="M114.18,214.55l67.23-38.83c.87-.49,1.57-.09,1.57.91v60.7a3.45,3.45,0,0,1-1.57,2.71l-67.23,38.82c-.86.5-1.56.1-1.56-.9V217.25A3.46,3.46,0,0,1,114.18,214.55Z"
          style="fill:currentColor;opacity:0.7000000000000001"
        ></path>
        <path
          d="M113.08,215.72l-2.94-1.7a3.26,3.26,0,0,0-.46,1.54v60.71a1.11,1.11,0,0,0,.45,1c.29.18,2.65,1.51,2.92,1.68a1.11,1.11,0,0,1-.43-1V217.25A3.19,3.19,0,0,1,113.08,215.72Z"
          style="opacity:0.15"
        ></path>
        <path
          d="M182.46,175.58c-.56-.34-2.61-1.5-2.86-1.65a1.1,1.1,0,0,0-1.12.1l-67.23,38.83a3.26,3.26,0,0,0-1.11,1.16l2.94,1.7a3.11,3.11,0,0,1,1.1-1.17l67.24-38.83A1.16,1.16,0,0,1,182.46,175.58Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M156.63,234.68l5.31,7.47-24-21.85-25.31,42.87V278c0,1,.7,1.4,1.56.9L181.41,240a3.45,3.45,0,0,0,1.57-2.71v-8.12l-17.91-12.9Z"
          style="fill:#fafafa;opacity:0.9500000000000001"
        ></path>
        <path
          d="M159.52,198.74c3.14-1.81,5.68-.34,5.68,3.28a12.53,12.53,0,0,1-5.68,9.83c-3.13,1.81-5.67.34-5.67-3.28A12.55,12.55,0,0,1,159.52,198.74Z"
          style="fill:#fafafa;opacity:0.9500000000000001"
        ></path>
      </g>
    </g>
    <g id="freepik--Envelope--inject-8">
      <g id="freepik--Letter--inject-8">
        <path
          d="M201.68,240.18l52.49-30.31a1.12,1.12,0,0,1,1.33,0c.3.26,2.19,1.22,2.52,1.45a1.67,1.67,0,0,1,.55,1.42V260a4.35,4.35,0,0,1-1.81,3.46l-9.22,5.32-47.09-27.2A3.45,3.45,0,0,1,201.68,240.18Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M201.68,240.18l52.49-30.31a1.12,1.12,0,0,1,1.33,0c.3.26,2.19,1.22,2.52,1.45a1.67,1.67,0,0,1,.55,1.42V260a4.35,4.35,0,0,1-1.81,3.46l-9.22,5.32-47.09-27.2A3.45,3.45,0,0,1,201.68,240.18Z"
          style="fill:#fff;opacity:0.5"
        ></path>
        <path
          d="M203,293.8c-.36-.23-2.27-1.28-2.59-1.5a1.62,1.62,0,0,1-.58-1.43V243.64a4.39,4.39,0,0,1,.58-2.09l47.09,27.2-43.27,25A1.13,1.13,0,0,1,203,293.8Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M203,293.8c-.36-.23-2.27-1.28-2.59-1.5a1.62,1.62,0,0,1-.58-1.43V243.64a4.39,4.39,0,0,1,.58-2.09l47.09,27.2-43.27,25A1.13,1.13,0,0,1,203,293.8Z"
          style="opacity:0.2"
        ></path>
        <path
          d="M204.27,293.73l52.49-30.3a4.35,4.35,0,0,0,1.81-3.46V212.74c0-1.34-.81-2-1.81-1.37l-52.49,30.3a4.35,4.35,0,0,0-1.81,3.46v47.23C202.46,293.7,203.27,294.31,204.27,293.73Z"
          style="fill:currentColor"
        ></path>
        <path
          d="M202.46,292.36v-.92l21.7-36L202.74,243.6c0-.13.1-.26.15-.38l26.69,11.12c.57.23,1.3-.19,1.86-1.07l26.66-41.89a1.45,1.45,0,0,1,.16.18l-21.39,36.57L258.57,259V260a4.75,4.75,0,0,1-.55,2.13L235.77,250l-3.15,5.38c-1.26,2.14-3,3.13-4.21,2.43l-3.15-1.74L203,293.8A1.71,1.71,0,0,1,202.46,292.36Z"
          style="opacity:0.2"
        ></path>
      </g>
    </g>
    <g id="freepik--Card--inject-8">
      <g id="freepik--card--inject-8">
        <path
          d="M111.55,349.84l-1.42-.82a1.82,1.82,0,0,1-.81-1.4v-57.8a1.82,1.82,0,0,1,.81-1.4l74.93-43.27a1.78,1.78,0,0,1,1.62,0l1.42.82a1.79,1.79,0,0,1,.81,1.4v57.8a1.76,1.76,0,0,1-.81,1.4l-74.93,43.27A1.78,1.78,0,0,1,111.55,349.84Z"
          style="fill:#f5f5f5"
        ></path>
        <path
          d="M113.17,290.17l74.93-43.26c.45-.26.81,0,.81.46v57.8a1.76,1.76,0,0,1-.81,1.4l-74.93,43.27c-.44.26-.81.05-.81-.47V291.58A1.82,1.82,0,0,1,113.17,290.17Z"
          style="fill:#f0f0f0"
        ></path>
        <path
          d="M188.1,246.91c-6.15,3.77-12.35,7.45-18.55,11.12l-18.68,10.91-18.79,10.73L122.65,285l-9.48,5.21,9.26-5.6,9.3-5.52,18.67-10.91,18.79-10.72C175.47,253.87,181.76,250.35,188.1,246.91Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M112.6,290.78l-3-1.76a1.66,1.66,0,0,0-.23.8v57.8a1.78,1.78,0,0,0,.8,1.4l1.42.82a1.81,1.81,0,0,0,1.46.08c-.37.12-.64-.1-.64-.55V291.58A1.6,1.6,0,0,1,112.6,290.78Z"
          style="fill:#e0e0e0"
        ></path>
        <polygon
          points="112.36 298.18 188.91 254 188.91 269.08 112.37 313.26 112.36 298.18"
          style="fill:currentColor"
        ></polygon>
        <g id="freepik--Cards--inject-8">
          <path
            d="M184.18,292.47v9.88a2.56,2.56,0,0,1-1.15,2l-10.38,6.07c-.64.37-1.16.07-1.16-.67v-9.88a2.57,2.57,0,0,1,1.16-2c2.83-1.63,7.54-4.43,10.38-6.06C183.67,291.44,184.18,291.74,184.18,292.47Z"
            style="fill:currentColor"
          ></path>
          <g style="clip-path:url(#freepik--clip-path--inject-8)">
            <path
              d="M184.18,292.47v9.88a2.56,2.56,0,0,1-1.15,2l-10.38,6.07c-.64.37-1.16.07-1.16-.67v-9.88a2.57,2.57,0,0,1,1.16-2c2.83-1.63,7.54-4.43,10.38-6.06C183.67,291.44,184.18,291.74,184.18,292.47Z"
              style="opacity:0.2"
            ></path>
            <polygon
              points="186.23 294.02 186.49 293.27 169.45 303.19 169.19 303.94 186.23 294.02"
              style="fill:#fff"
            ></polygon>
            <polygon
              points="179.57 302.75 180.1 302.14 180.1 297.11 179.57 297.72 179.57 302.75"
              style="fill:#fff"
            ></polygon>
            <polygon
              points="175.58 305.15 176.11 304.54 176.11 299.51 175.58 300.12 175.58 305.15"
              style="fill:#fff"
            ></polygon>
            <polygon
              points="186.23 299.07 186.49 298.31 169.45 308.23 169.19 308.99 186.23 299.07"
              style="fill:#fff"
            ></polygon>
          </g>
          <path
            d="M117.21,304.5a2.35,2.35,0,0,1-.64-2,5.55,5.55,0,0,1,.63-2.7A4.5,4.5,0,0,1,119,298a3.77,3.77,0,0,1,.88-.38,1.4,1.4,0,0,1,.67,0,.11.11,0,0,1,.06.11.41.41,0,0,1,0,.17l-.23.68a.32.32,0,0,1-.09.15.23.23,0,0,1-.12,0,1.53,1.53,0,0,0-1,.26,2.8,2.8,0,0,0-1.07,1.14,4,4,0,0,0-.37,1.79c0,.7.13,1.14.39,1.34s.63.16,1.08-.1a3.36,3.36,0,0,0,1-.93l.12-.12s.06,0,.09.05l.26.44a.15.15,0,0,1,0,.09.38.38,0,0,1-.07.18,4.63,4.63,0,0,1-1.61,1.42C118.23,304.72,117.63,304.79,117.21,304.5Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M121.29,302.73c-.05,0-.07-.12-.07-.25v-5.26a.67.67,0,0,1,.07-.33.51.51,0,0,1,.23-.23l1.68-1c1.13-.65,1.69-.32,1.69,1a3.48,3.48,0,0,1-.2,1.18,3.27,3.27,0,0,1-.59,1l.7,1.63a.43.43,0,0,1,0,.12.28.28,0,0,1-.14.24l-.74.42a.26.26,0,0,1-.14,0s-.06,0-.08-.09l-.61-1.6-.8.46v1.74a.73.73,0,0,1-.06.32.56.56,0,0,1-.23.23l-.5.29C121.41,302.75,121.33,302.76,121.29,302.73Zm1.75-4.09a1.59,1.59,0,0,0,.57-.55,1.55,1.55,0,0,0,.19-.78q0-.46-.18-.57c-.11-.08-.28-.05-.51.08l-.8.46v1.77Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M125.5,300.29a.31.31,0,0,1-.07-.24v-5.26a.89.89,0,0,1,.07-.34.72.72,0,0,1,.23-.23l2.76-1.59c.11-.06.18-.07.22,0s.06.12.06.25v.33a.75.75,0,0,1-.06.33.5.5,0,0,1-.22.21l-2,1.14v1.4l1.37-.79c.11-.07.18-.08.22,0a.38.38,0,0,1,.06.26V296a.73.73,0,0,1-.06.32.47.47,0,0,1-.22.22l-1.37.79v1.41l2-1.13c.1-.06.18-.07.22,0a.39.39,0,0,1,.06.26v.32a.67.67,0,0,1-.06.32.66.66,0,0,1-.22.22l-2.76,1.59C125.63,300.32,125.55,300.33,125.5,300.29Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M129.32,298.09s-.07-.12-.07-.25v-5.26a.67.67,0,0,1,.07-.33.56.56,0,0,1,.23-.23l1.4-.81c.76-.43,1.33-.52,1.73-.27s.59.91.59,1.95a5.31,5.31,0,0,1-.6,2.62,4.47,4.47,0,0,1-1.72,1.73l-1.4.81C129.44,298.11,129.37,298.13,129.32,298.09Zm1.68-1.95a2.15,2.15,0,0,0,.89-.95,4,4,0,0,0,.29-1.67,2,2,0,0,0-.29-1.33c-.2-.18-.49-.15-.89.08l-.65.37v3.88Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M133.88,295.46a.35.35,0,0,1-.07-.25V290a.81.81,0,0,1,.07-.33.57.57,0,0,1,.23-.23l.5-.29c.11-.07.19-.08.24,0s.06.12.06.26v5.25a.81.81,0,0,1-.06.33.67.67,0,0,1-.24.23l-.5.29C134,295.48,133.93,295.49,133.88,295.46Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M136.76,293.79a.31.31,0,0,1-.07-.24V289l-1.09.63c-.1.06-.18.07-.22,0s-.06-.12-.06-.25v-.33a.75.75,0,0,1,.06-.33.56.56,0,0,1,.22-.21l3.29-1.9c.11-.06.18-.08.22,0a.35.35,0,0,1,.07.26v.32a.72.72,0,0,1-.07.32.47.47,0,0,1-.22.22l-1.09.63v4.57a.57.57,0,0,1-.31.56l-.49.28C136.89,293.81,136.81,293.83,136.76,293.79Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M142.48,289.91c-.42-.3-.63-1-.63-2a5.66,5.66,0,0,1,.62-2.7,4.63,4.63,0,0,1,1.78-1.84,3.54,3.54,0,0,1,.88-.38,1.29,1.29,0,0,1,.67,0,.11.11,0,0,1,.07.11.79.79,0,0,1,0,.17l-.24.68a.32.32,0,0,1-.09.15.19.19,0,0,1-.11,0,1.56,1.56,0,0,0-1,.26,2.66,2.66,0,0,0-1.06,1.14,3.94,3.94,0,0,0-.38,1.79c0,.69.13,1.14.4,1.34s.62.16,1.08-.1a3.59,3.59,0,0,0,1.05-.93.69.69,0,0,1,.11-.12s.06,0,.09,0l.26.45a.16.16,0,0,1,0,.09.42.42,0,0,1-.08.18,4.69,4.69,0,0,1-1.6,1.42C143.51,290.13,142.91,290.2,142.48,289.91Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M146.2,288.29a1,1,0,0,1,0-.15l1.72-6.47a1.3,1.3,0,0,1,.1-.27.5.5,0,0,1,.17-.15l.48-.28a.23.23,0,0,1,.16,0,.26.26,0,0,1,.1.15l1.73,4.48a.69.69,0,0,1,0,.13.23.23,0,0,1-.12.22l-.81.47a.23.23,0,0,1-.14,0,.2.2,0,0,1-.07-.11l-.31-.87-1.61.94-.3,1.22a1.21,1.21,0,0,1-.08.19.43.43,0,0,1-.14.12l-.8.46C146.24,288.42,146.2,288.39,146.2,288.29Zm2.8-3.72-.55-1.6-.56,2.24Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M151.32,285.39s-.07-.12-.07-.25v-5.26a.67.67,0,0,1,.07-.33.51.51,0,0,1,.23-.23l1.68-1c1.13-.65,1.69-.32,1.69,1a3.58,3.58,0,0,1-.2,1.19,3.23,3.23,0,0,1-.59,1l.7,1.63a.5.5,0,0,1,0,.12.28.28,0,0,1-.14.24L154,284c-.06,0-.11.06-.14.05s-.06,0-.08-.09l-.61-1.6-.79.46v1.74a.81.81,0,0,1-.06.33.61.61,0,0,1-.23.22l-.5.29C151.44,285.41,151.36,285.43,151.32,285.39Zm1.75-4.09a1.65,1.65,0,0,0,.57-.54,1.52,1.52,0,0,0,.19-.79c0-.3-.06-.5-.17-.57s-.29,0-.52.08l-.8.46v1.78Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M155.53,283a.35.35,0,0,1-.07-.25v-5.26a.81.81,0,0,1,.07-.33.57.57,0,0,1,.23-.23l1.4-.81c.76-.44,1.34-.53,1.73-.27s.59.9.59,1.95a5.34,5.34,0,0,1-.6,2.62,4.47,4.47,0,0,1-1.72,1.73l-1.4.81C155.66,283,155.58,283,155.53,283Zm1.68-1.95a2.2,2.2,0,0,0,.89-.95,4.06,4.06,0,0,0,.29-1.68,2,2,0,0,0-.29-1.32c-.2-.18-.49-.16-.89.07l-.65.38v3.87Z"
            style="fill:#455a64"
          ></path>
          <g style="opacity:0.15">
            <path
              d="M117.21,304.5a2.35,2.35,0,0,1-.64-2,5.55,5.55,0,0,1,.63-2.7A4.5,4.5,0,0,1,119,298a3.77,3.77,0,0,1,.88-.38,1.4,1.4,0,0,1,.67,0,.11.11,0,0,1,.06.11.41.41,0,0,1,0,.17l-.23.68a.32.32,0,0,1-.09.15.23.23,0,0,1-.12,0,1.53,1.53,0,0,0-1,.26,2.8,2.8,0,0,0-1.07,1.14,4,4,0,0,0-.37,1.79c0,.7.13,1.14.39,1.34s.63.16,1.08-.1a3.36,3.36,0,0,0,1-.93l.12-.12s.06,0,.09.05l.26.44a.15.15,0,0,1,0,.09.38.38,0,0,1-.07.18,4.63,4.63,0,0,1-1.61,1.42C118.23,304.72,117.63,304.79,117.21,304.5Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M121.29,302.73c-.05,0-.07-.12-.07-.25v-5.26a.67.67,0,0,1,.07-.33.51.51,0,0,1,.23-.23l1.68-1c1.13-.65,1.69-.32,1.69,1a3.48,3.48,0,0,1-.2,1.18,3.27,3.27,0,0,1-.59,1l.7,1.63a.43.43,0,0,1,0,.12.28.28,0,0,1-.14.24l-.74.42a.26.26,0,0,1-.14,0s-.06,0-.08-.09l-.61-1.6-.8.46v1.74a.73.73,0,0,1-.06.32.56.56,0,0,1-.23.23l-.5.29C121.41,302.75,121.33,302.76,121.29,302.73Zm1.75-4.09a1.59,1.59,0,0,0,.57-.55,1.55,1.55,0,0,0,.19-.78q0-.46-.18-.57c-.11-.08-.28-.05-.51.08l-.8.46v1.77Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M125.5,300.29a.31.31,0,0,1-.07-.24v-5.26a.89.89,0,0,1,.07-.34.72.72,0,0,1,.23-.23l2.76-1.59c.11-.06.18-.07.22,0s.06.12.06.25v.33a.75.75,0,0,1-.06.33.5.5,0,0,1-.22.21l-2,1.14v1.4l1.37-.79c.11-.07.18-.08.22,0a.38.38,0,0,1,.06.26V296a.73.73,0,0,1-.06.32.47.47,0,0,1-.22.22l-1.37.79v1.41l2-1.13c.1-.06.18-.07.22,0a.39.39,0,0,1,.06.26v.32a.67.67,0,0,1-.06.32.66.66,0,0,1-.22.22l-2.76,1.59C125.63,300.32,125.55,300.33,125.5,300.29Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M129.32,298.09s-.07-.12-.07-.25v-5.26a.67.67,0,0,1,.07-.33.56.56,0,0,1,.23-.23l1.4-.81c.76-.43,1.33-.52,1.73-.27s.59.91.59,1.95a5.31,5.31,0,0,1-.6,2.62,4.47,4.47,0,0,1-1.72,1.73l-1.4.81C129.44,298.11,129.37,298.13,129.32,298.09Zm1.68-1.95a2.15,2.15,0,0,0,.89-.95,4,4,0,0,0,.29-1.67,2,2,0,0,0-.29-1.33c-.2-.18-.49-.15-.89.08l-.65.37v3.88Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M133.88,295.46a.35.35,0,0,1-.07-.25V290a.81.81,0,0,1,.07-.33.57.57,0,0,1,.23-.23l.5-.29c.11-.07.19-.08.24,0s.06.12.06.26v5.25a.81.81,0,0,1-.06.33.67.67,0,0,1-.24.23l-.5.29C134,295.48,133.93,295.49,133.88,295.46Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M136.76,293.79a.31.31,0,0,1-.07-.24V289l-1.09.63c-.1.06-.18.07-.22,0s-.06-.12-.06-.25v-.33a.75.75,0,0,1,.06-.33.56.56,0,0,1,.22-.21l3.29-1.9c.11-.06.18-.08.22,0a.35.35,0,0,1,.07.26v.32a.72.72,0,0,1-.07.32.47.47,0,0,1-.22.22l-1.09.63v4.57a.57.57,0,0,1-.31.56l-.49.28C136.89,293.81,136.81,293.83,136.76,293.79Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M142.48,289.91c-.42-.3-.63-1-.63-2a5.66,5.66,0,0,1,.62-2.7,4.63,4.63,0,0,1,1.78-1.84,3.54,3.54,0,0,1,.88-.38,1.29,1.29,0,0,1,.67,0,.11.11,0,0,1,.07.11.79.79,0,0,1,0,.17l-.24.68a.32.32,0,0,1-.09.15.19.19,0,0,1-.11,0,1.56,1.56,0,0,0-1,.26,2.66,2.66,0,0,0-1.06,1.14,3.94,3.94,0,0,0-.38,1.79c0,.69.13,1.14.4,1.34s.62.16,1.08-.1a3.59,3.59,0,0,0,1.05-.93.69.69,0,0,1,.11-.12s.06,0,.09,0l.26.45a.16.16,0,0,1,0,.09.42.42,0,0,1-.08.18,4.69,4.69,0,0,1-1.6,1.42C143.51,290.13,142.91,290.2,142.48,289.91Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M146.2,288.29a1,1,0,0,1,0-.15l1.72-6.47a1.3,1.3,0,0,1,.1-.27.5.5,0,0,1,.17-.15l.48-.28a.23.23,0,0,1,.16,0,.26.26,0,0,1,.1.15l1.73,4.48a.69.69,0,0,1,0,.13.23.23,0,0,1-.12.22l-.81.47a.23.23,0,0,1-.14,0,.2.2,0,0,1-.07-.11l-.31-.87-1.61.94-.3,1.22a1.21,1.21,0,0,1-.08.19.43.43,0,0,1-.14.12l-.8.46C146.24,288.42,146.2,288.39,146.2,288.29Zm2.8-3.72-.55-1.6-.56,2.24Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M151.32,285.39s-.07-.12-.07-.25v-5.26a.67.67,0,0,1,.07-.33.51.51,0,0,1,.23-.23l1.68-1c1.13-.65,1.69-.32,1.69,1a3.58,3.58,0,0,1-.2,1.19,3.23,3.23,0,0,1-.59,1l.7,1.63a.5.5,0,0,1,0,.12.28.28,0,0,1-.14.24L154,284c-.06,0-.11.06-.14.05s-.06,0-.08-.09l-.61-1.6-.79.46v1.74a.81.81,0,0,1-.06.33.61.61,0,0,1-.23.22l-.5.29C151.44,285.41,151.36,285.43,151.32,285.39Zm1.75-4.09a1.65,1.65,0,0,0,.57-.54,1.52,1.52,0,0,0,.19-.79c0-.3-.06-.5-.17-.57s-.29,0-.52.08l-.8.46v1.78Z"
              style="fill:#455a64"
            ></path>
            <path
              d="M155.53,283a.35.35,0,0,1-.07-.25v-5.26a.81.81,0,0,1,.07-.33.57.57,0,0,1,.23-.23l1.4-.81c.76-.44,1.34-.53,1.73-.27s.59.9.59,1.95a5.34,5.34,0,0,1-.6,2.62,4.47,4.47,0,0,1-1.72,1.73l-1.4.81C155.66,283,155.58,283,155.53,283Zm1.68-1.95a2.2,2.2,0,0,0,.89-.95,4.06,4.06,0,0,0,.29-1.68,2,2,0,0,0-.29-1.32c-.2-.18-.49-.16-.89.07l-.65.38v3.87Z"
              style="fill:#455a64"
            ></path>
          </g>
          <path
            d="M123.51,324.8l3-1.7c.38-.22.69-.05.69.4v.9a1.54,1.54,0,0,1-.69,1.21l-3,1.71c-.39.22-.7,0-.7-.41V326A1.55,1.55,0,0,1,123.51,324.8Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M117,328.57l3-1.71c.39-.22.7,0,.7.4v.91a1.52,1.52,0,0,1-.7,1.2l-3,1.71c-.38.22-.69,0-.69-.4v-.9A1.54,1.54,0,0,1,117,328.57Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M154.34,313.28l-36.83,21.27a2.55,2.55,0,0,0-1.16,2v1.09c0,.74.52,1,1.16.67l36.83-21.26a2.56,2.56,0,0,0,1.15-2V314C155.49,313.21,155,312.91,154.34,313.28Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M123.51,324.8l3-1.7c.38-.22.69-.05.69.4v.9a1.54,1.54,0,0,1-.69,1.21l-3,1.71c-.39.22-.7,0-.7-.41V326A1.55,1.55,0,0,1,123.51,324.8Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M117,328.57l3-1.71c.39-.22.7,0,.7.4v.91a1.52,1.52,0,0,1-.7,1.2l-3,1.71c-.38.22-.69,0-.69-.4v-.9A1.54,1.54,0,0,1,117,328.57Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M154.34,313.28l-36.83,21.27a2.55,2.55,0,0,0-1.16,2v1.09c0,.74.52,1,1.16.67l36.83-21.26a2.56,2.56,0,0,0,1.15-2V314C155.49,313.21,155,312.91,154.34,313.28Z"
            style="fill:#455a64"
          ></path>
          <path
            d="M117,318.25l.14-.08c.42-.24.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.48l-.12.07c-.42.26-.76,0-.76-.54v-3A1.83,1.83,0,0,1,117,318.25Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M120.93,316l.14-.08c.42-.24.75,0,.75.57v2.9a1.87,1.87,0,0,1-.76,1.47l-.12.08c-.42.25-.76,0-.76-.54v-3A1.81,1.81,0,0,1,120.93,316Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M124.82,313.79l.14-.08c.42-.24.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.47l-.12.07c-.42.26-.76,0-.76-.53v-3A1.81,1.81,0,0,1,124.82,313.79Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M128.71,311.56l.14-.08c.42-.24.75,0,.75.57V315a1.9,1.9,0,0,1-.76,1.47l-.12.07c-.42.26-.76,0-.76-.53v-3A1.81,1.81,0,0,1,128.71,311.56Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M135.24,307.74l.13-.08c.42-.24.76,0,.76.58v2.9a1.88,1.88,0,0,1-.77,1.47l-.11.07c-.42.26-.77,0-.77-.54v-3A1.81,1.81,0,0,1,135.24,307.74Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M139.13,305.51l.13-.08c.42-.24.76,0,.76.57v2.9a1.88,1.88,0,0,1-.77,1.48l-.11.07c-.43.26-.77,0-.77-.54v-3A1.81,1.81,0,0,1,139.13,305.51Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M143,303.28l.13-.08c.42-.24.76,0,.76.57v2.9a1.88,1.88,0,0,1-.77,1.48l-.11.07c-.43.26-.77,0-.77-.54v-3A1.81,1.81,0,0,1,143,303.28Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M146.91,301.05,147,301c.42-.24.76,0,.76.57v2.9a1.87,1.87,0,0,1-.77,1.47l-.11.08c-.43.26-.77,0-.77-.54v-3A1.79,1.79,0,0,1,146.91,301.05Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M153.43,297.24l.14-.08c.41-.24.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.47l-.12.07c-.42.26-.76,0-.76-.53v-3A1.78,1.78,0,0,1,153.43,297.24Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M157.32,295l.14-.08c.41-.24.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.47l-.12.07c-.42.26-.76,0-.76-.53v-3A1.78,1.78,0,0,1,157.32,295Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M161.21,292.77l.14-.07c.41-.25.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.47l-.12.07c-.42.26-.76,0-.76-.54v-3A1.8,1.8,0,0,1,161.21,292.77Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M165.1,290.54l.14-.08c.41-.24.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.48l-.12.07c-.42.26-.76,0-.76-.54v-3A1.8,1.8,0,0,1,165.1,290.54Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M171.62,286.73l.14-.08c.42-.24.76,0,.76.57v2.9a1.91,1.91,0,0,1-.77,1.48l-.12.07c-.42.26-.76,0-.76-.54v-3A1.83,1.83,0,0,1,171.62,286.73Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M175.51,284.5l.14-.08c.42-.24.75,0,.75.57v2.9a1.91,1.91,0,0,1-.77,1.48l-.12.07c-.42.26-.76,0-.76-.54v-3A1.81,1.81,0,0,1,175.51,284.5Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M179.4,282.27l.14-.08c.42-.24.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.47l-.12.08c-.42.25-.76,0-.76-.54v-3A1.81,1.81,0,0,1,179.4,282.27Z"
            style="fill:currentColor"
          ></path>
          <path
            d="M183.29,280l.14-.08c.42-.24.75,0,.75.57v2.9a1.9,1.9,0,0,1-.76,1.47l-.12.07c-.42.26-.76,0-.76-.53v-3A1.81,1.81,0,0,1,183.29,280Z"
            style="fill:currentColor"
          ></path>
        </g>
        <polygon
          points="112.36 298.18 109.33 296.42 109.33 311.53 112.37 313.26 112.36 298.18"
          style="fill:currentColor"
        ></polygon>
        <polygon
          points="112.36 298.18 109.33 296.42 109.33 311.53 112.37 313.26 112.36 298.18"
          style="opacity:0.2"
        ></polygon>
      </g>
    </g>
    <g id="freepik--Fingerprint--inject-8">
      <g id="freepik--fingerprint--inject-8">
        <path
          d="M109.61,153v48a2.23,2.23,0,0,0,1,1.75l1.77,1a2.21,2.21,0,0,0,2,0l34-19.56a2.23,2.23,0,0,0,1-1.75v-48a2.22,2.22,0,0,0-1-1.75l-1.78-1a2.25,2.25,0,0,0-2,0l-34,19.57A2.22,2.22,0,0,0,109.61,153Z"
          style="fill:#ebebeb"
        ></path>
        <path
          d="M149.43,134.3c-.07-.52-.49-.71-1-.42l-34,19.57a2.08,2.08,0,0,0-.72.75L109.91,152a2.06,2.06,0,0,1,.71-.75l34-19.57a2.27,2.27,0,0,1,2,0l1.77,1A2.28,2.28,0,0,1,149.43,134.3Z"
          style="fill:#f5f5f5"
        ></path>
        <path
          d="M114.24,203.88s0,0-.06,0l-.12,0h0l-.21.06a2.38,2.38,0,0,1-1.22-.1l-.22-.11-1.78-1a2.26,2.26,0,0,1-1-1.75V153a2,2,0,0,1,.3-1l3.79,2.19a2.12,2.12,0,0,0-.29,1v48a.82.82,0,0,0,.15.53.54.54,0,0,0,.54.18Z"
          style="fill:#e0e0e0"
        ></path>
        <path
          d="M139.48,172.57a5.49,5.49,0,0,1-3.11-.84.32.32,0,0,1,.36-.54,6,6,0,0,0,4.5.56.32.32,0,0,1,.38.26.32.32,0,0,1-.26.38A10.62,10.62,0,0,1,139.48,172.57Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M136,170.92a.36.36,0,0,1-.25-.11c-1.14-1.28-1.26-3.22-1.38-5.27,0-.35-.05-.7-.07-1a2.49,2.49,0,0,0-.73-1.9,1.41,1.41,0,0,0-1.25-.08.33.33,0,1,1-.18-.63,2.09,2.09,0,0,1,1.81.17,3,3,0,0,1,1,2.39c0,.35.05.71.08,1.06.12,2,.23,3.77,1.22,4.88a.32.32,0,0,1,0,.46A.34.34,0,0,1,136,170.92Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M129.92,175.58a.31.31,0,0,1-.3-.2c-1.1-2.68-1.68-10.79,1.54-13a.32.32,0,0,1,.45.08.33.33,0,0,1-.08.46c-2.79,1.93-2.36,9.67-1.31,12.23a.33.33,0,0,1-.18.43A.25.25,0,0,1,129.92,175.58Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M137.56,180.59a8.07,8.07,0,0,1-7.4-4.1.33.33,0,0,1,.12-.44.34.34,0,0,1,.45.12c1.6,2.82,4.51,4.13,8.2,3.69a.33.33,0,0,1,.36.28.33.33,0,0,1-.28.37C138.51,180.56,138,180.59,137.56,180.59Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M138.28,178.7a7.28,7.28,0,0,1-5.25-1.87c-2-1.94-2.87-5.37-2.54-10.2a3.36,3.36,0,0,1,1.38-2.55,1.18,1.18,0,0,1,1.23,0,1.39,1.39,0,0,1,.62,1.27c-.06,3.89.65,6.4,2.17,7.69,1.16,1,2.82,1.28,5.07.88a.32.32,0,1,1,.11.64c-2.45.44-4.28.1-5.61-1-1.67-1.43-2.45-4.11-2.39-8.2a.78.78,0,0,0-.3-.7.54.54,0,0,0-.56,0,2.75,2.75,0,0,0-1.07,2c-.32,4.63.47,7.89,2.34,9.69,1.41,1.36,3.48,1.91,6.14,1.62a.32.32,0,0,1,.07.64A11.54,11.54,0,0,1,138.28,178.7Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M136.76,182.79a8.69,8.69,0,0,1-6.4-2.37c-2.36-2.34-3.4-6.32-3.09-11.84.17-3.06,1.3-6.87,4-8.41l.11-.06a3.19,3.19,0,0,1,3.15-.23c1.1.59,1.78,2,1.92,3.84l0,.33c.14,1.91.29,3.89,1.53,4.87a4.52,4.52,0,0,0,3.62.51.31.31,0,0,1,.37.27.32.32,0,0,1-.27.37,5.1,5.1,0,0,1-4.12-.64c-1.47-1.16-1.64-3.37-1.78-5.33l0-.33c-.12-1.66-.68-2.84-1.57-3.32a2.6,2.6,0,0,0-2.54.23l-.1.06c-2.43,1.4-3.48,5.12-3.63,7.88-.3,5.32.67,9.14,2.9,11.34a8.49,8.49,0,0,0,6.84,2.15.33.33,0,1,1,0,.65Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M136.2,185.11h0a10.42,10.42,0,0,1-3.38-.61.33.33,0,0,1-.2-.42.34.34,0,0,1,.41-.2,9.69,9.69,0,0,0,3.17.58.32.32,0,0,1,.33.32A.33.33,0,0,1,136.2,185.11Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M131.79,184.16a.35.35,0,0,1-.14,0,8.6,8.6,0,0,1-4.35-4,.32.32,0,0,1,.15-.43.33.33,0,0,1,.44.15,7.8,7.8,0,0,0,4,3.74.33.33,0,0,1,.17.43A.34.34,0,0,1,131.79,184.16Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M127,178.88a.32.32,0,0,1-.31-.22c-1.27-3.64-2-11.11,1-16.74a.32.32,0,0,1,.44-.13.32.32,0,0,1,.13.44c-2.95,5.45-2.19,12.69-1,16.22a.32.32,0,0,1-.2.41A.18.18,0,0,1,127,178.88Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M137.63,163.7a.32.32,0,0,1-.32-.29c-.31-3.28-1.2-4.85-3-5.24s-3.79.82-5.38,3a.33.33,0,1,1-.53-.38c1.78-2.44,4.09-3.69,6.05-3.26,2.63.58,3.23,3.26,3.46,5.8a.33.33,0,0,1-.29.36Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M140.45,168.26a2.83,2.83,0,0,1-1.52-.4,3.37,3.37,0,0,1-1.41-2.61.33.33,0,1,1,.65-.08,2.78,2.78,0,0,0,1.1,2.14,2.93,2.93,0,0,0,2.48.06.32.32,0,0,1,.21.61A4.49,4.49,0,0,1,140.45,168.26Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M134.88,186.87h0a13.29,13.29,0,0,1-3.38-.86c-5.67-2.32-7.94-7-7.6-15.71.2-5,2.76-11.44,7.28-14.05,1.88-1.08,3.52-1.29,4.87-.63,1.62.8,2.69,2.81,3.1,5.82.45,3.33,1.29,3.91,2.86,3.85h0a.32.32,0,0,1,.33.32.32.32,0,0,1-.32.33c-2.14,0-3.06-1.05-3.51-4.41-.38-2.79-1.33-4.63-2.74-5.32-1.15-.57-2.58-.36-4.26.6-4.32,2.5-6.77,8.69-7,13.52-.41,10.52,3.4,13.52,7.18,15.07a12.43,12.43,0,0,0,3.21.82.33.33,0,0,1,0,.65Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M142,163.7c-1.82,0-2.21-2.36-2.52-4.26,0-.27-.08-.54-.13-.79a.32.32,0,0,1,.26-.38.32.32,0,0,1,.38.26c0,.26.09.53.14.81.28,1.76.63,3.75,1.92,3.71a.32.32,0,0,1,.34.31.33.33,0,0,1-.32.34Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M139.36,157.75a.32.32,0,0,1-.31-.23c-.66-2.12-1.9-3.45-3.52-3.75a.33.33,0,0,1-.26-.38.34.34,0,0,1,.38-.26c1.84.34,3.3,1.87,4,4.2a.33.33,0,0,1-.21.41Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M128.7,156.78a.31.31,0,0,1-.22-.09.32.32,0,0,1,0-.46,9.59,9.59,0,0,1,6.14-3.17.35.35,0,0,1,.34.32.33.33,0,0,1-.32.33,9.05,9.05,0,0,0-5.69,3A.33.33,0,0,1,128.7,156.78Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M123.32,166h-.07a.32.32,0,0,1-.24-.39,21.61,21.61,0,0,1,4.33-8.38.33.33,0,0,1,.46,0,.31.31,0,0,1,0,.45,20.87,20.87,0,0,0-4.2,8.11A.33.33,0,0,1,123.32,166Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M122.64,175.6a.33.33,0,0,1-.33-.29,27.8,27.8,0,0,1,.28-8.33.33.33,0,0,1,.64.12,27.19,27.19,0,0,0-.27,8.14.33.33,0,0,1-.29.36Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M125,183.5a.33.33,0,0,1-.28-.16,19.4,19.4,0,0,1-2.22-6.37.33.33,0,0,1,.27-.38.32.32,0,0,1,.37.27,18.78,18.78,0,0,0,2.14,6.14.33.33,0,0,1-.12.45A.33.33,0,0,1,125,183.5Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M132.12,188.93a.25.25,0,0,1-.12,0,14.33,14.33,0,0,1-6.34-4.41.33.33,0,0,1,.52-.39,13.81,13.81,0,0,0,6.07,4.2.32.32,0,0,1,.17.42A.31.31,0,0,1,132.12,188.93Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M129.66,190.33l-.12,0c-5.92-2.43-9-8.64-9-18,0-7.24,3.3-16.36,10.67-20.4a7.23,7.23,0,0,1,7.2,0c2.54,1.5,4,4.55,4,8.36a.32.32,0,0,1-.33.32.32.32,0,0,1-.32-.32c0-3.63-1.31-6.4-3.69-7.8a6.55,6.55,0,0,0-6.55,0c-7.14,3.91-10.33,12.78-10.33,19.83,0,6.31,1.5,14.45,8.64,17.37a.33.33,0,0,1-.12.63Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M138.73,176.72l-.68,0a.33.33,0,1,1,0-.65,13.53,13.53,0,0,0,2.41-.1.34.34,0,0,1,.37.29.33.33,0,0,1-.29.36A15.34,15.34,0,0,1,138.73,176.72Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M136.88,176.59h-.06a5,5,0,0,1-3.55-2.59.32.32,0,0,1,.15-.43.33.33,0,0,1,.44.15,4.32,4.32,0,0,0,3.08,2.23.32.32,0,0,1,.26.38A.33.33,0,0,1,136.88,176.59Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M133.06,173.19a.33.33,0,0,1-.3-.2,15.24,15.24,0,0,1-.92-7.13.32.32,0,0,1,.35-.3.32.32,0,0,1,.3.35,14.89,14.89,0,0,0,.86,6.82.32.32,0,0,1-.16.43Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M139.64,150.12a.33.33,0,0,1-.19-.06,7.06,7.06,0,0,0-5.17-1,.32.32,0,0,1-.39-.23.33.33,0,0,1,.23-.4,7.7,7.7,0,0,1,5.71,1.06.32.32,0,0,1,.07.46A.33.33,0,0,1,139.64,150.12Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M125.92,154.11a.34.34,0,0,1-.21-.08.32.32,0,0,1,0-.46,17.83,17.83,0,0,1,6.85-4.66.32.32,0,0,1,.42.19.32.32,0,0,1-.19.42,17.23,17.23,0,0,0-6.59,4.48A.36.36,0,0,1,125.92,154.11Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M126.14,191.1a.4.4,0,0,1-.15,0,7.87,7.87,0,0,1-2.6-2.11.32.32,0,0,1,0-.46.33.33,0,0,1,.46,0,7.19,7.19,0,0,0,2.39,1.94.33.33,0,0,1-.14.62Z"
          style="fill:#455a64"
        ></path>
        <path
          d="M122.8,187.88a.34.34,0,0,1-.26-.12,14.57,14.57,0,0,1-2.29-4.11.33.33,0,0,1,.62-.21,13.55,13.55,0,0,0,2.18,3.91.33.33,0,0,1,0,.46A.31.31,0,0,1,122.8,187.88Z"
          style="fill:#455a64"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
